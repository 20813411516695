<template>
  <div class="LoadingPlaceholder loading-blinking blinking">
    {{ text ? text : $t('ins_general.loading_component') }}
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'LoadingPlaceholder',
    props: {
      text: { type: String, required: false },
    },
    setup() {},
  });
</script>

<style lang="scss">
  .LoadingPlaceholder {
    text-align: center;
  }
</style>
