<template>
  <ResetPasswordPage />
</template>

<script lang="ts">
  import { defineAsyncComponent, defineComponent } from 'vue';

  import ErrorPlaceholder from '../../components/helpers/ErrorPlaceholder.vue';
  import LoadingPlaceholder from '../../components/helpers/LoadingPlaceholder.vue';

  const ResetPasswordPage = defineAsyncComponent({
    loader: () =>
      import(/*webpackChunkName: "ResetPasswordPage.vue"*/ '../../pages/ResetPasswordPage.vue'),
    loadingComponent: LoadingPlaceholder,
    errorComponent: ErrorPlaceholder,
  });

  export default defineComponent({
    components: { ResetPasswordPage },
    setup() {},
  });
</script>
