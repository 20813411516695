<template>
  <li class="Notification p-relative">
    <h4 class="font-title-medium">{{ notification.title }}</h4>
    <p class="capitalize-first" v-html="body"></p>
    <div v-if="notification.actions && notification.actions.length">
      <AtButton
        v-for="(action, index) in notification.actions"
        :key="index"
        @click="clickAction(action)"
        visual="link"
        class="action-btn"
      >
        {{ action.title }}
      </AtButton>
    </div>
    <button
      v-if="notification.user_deletable"
      class="Notification-delete p-absolute"
      @click="$emit('delete')"
    >
      <TrashIcon :size="20" />
    </button>
  </li>
</template>

<script lang="ts">
  import sanitizeHtml from 'sanitize-html';
  import { PropType, computed, defineComponent } from 'vue';
  import { TrashIcon } from 'vue-tabler-icons';

  import { AtButton } from '@agritask/vue-components';

  import useNotifications from '@/src/components/hooks/notifications/useNotifications';
  import usePages from '@/src/components/hooks/usePages';

  export default defineComponent({
    name: 'Notification',
    components: { AtButton, TrashIcon },
    props: {
      notification: { type: Object as PropType<Notifications.Notification>, required: true },
    },
    emits: ['delete', 'pageChanging'],
    setup(props, { emit }) {
      const { gotoTableReviewPage } = usePages();
      const { setAsReadNotification } = useNotifications();

      const clickAction = (action: Notifications.NotificationAction) => {
        const { type: actionType, params } = action;
        const { type: paramsType, entity_type, job_id } = params;

        setAsReadNotification(props.notification.id);

        switch (actionType) {
          case 'PAGE':
            switch (paramsType) {
              case 'review':
                emit('pageChanging');
                gotoTableReviewPage(job_id, entity_type);
                return;
            }
        }
        throw 'invalid notification action';
      };

      const body = computed(() =>
        sanitizeHtml(props.notification.body, { allowedTags: ['b', 'i', 'strong', 'br'] })
      );

      return { clickAction, body };
    },
  });
</script>

<style lang="scss">
  .Notification {
    &-delete {
      opacity: 0;
      top: 0;
      inset-inline-end: 0;
      color: var(--red);
      transition: opacity 0.2s;
      margin: 6px;
    }

    &:hover,
    &:focus {
      .Notification-delete {
        opacity: 1;
      }
    }

    .action-btn {
      --button-height: 0;
    }
  }
</style>
