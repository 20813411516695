class EventEmitter<T extends Object> {
  // eslint-disable-next-line no-unused-vars
  private events: Record<string, Array<(payload: T[keyof T]) => void>> = {};

  addEventListener<K extends keyof T & string>(
    eventType: K,
    // eslint-disable-next-line no-unused-vars
    handler: (payload: T[K] & { event: K }) => void
  ) {
    this.events[eventType] = this.events[eventType] || [];
    // eslint-disable-next-line no-unused-vars
    this.events[eventType].push(handler as (payload: T[keyof T]) => void);
  }

  removeEventListener<K extends keyof T & string>(eventType: K, handler: Function) {
    this.events[eventType]?.filter((func) => func !== handler);
  }

  emit<K extends keyof T & string>(eventType: K, payload: T[K]) {
    this.events[eventType]?.forEach((handler) => {
      handler({ ...payload, event: eventType });
    });
  }

  clear() {
    this.events = {};
  }
}

export default EventEmitter;
