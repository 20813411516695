<template>
  <AcceptTermsPage />
</template>

<script lang="ts">
  import { defineAsyncComponent, defineComponent } from 'vue';

  import ErrorPlaceholder from '../../components/helpers/ErrorPlaceholder.vue';
  import LoadingPlaceholder from '../../components/helpers/LoadingPlaceholder.vue';

  const AcceptTermsPage = defineAsyncComponent({
    loader: () =>
      import(/*webpackChunkName: "AcceptTermsPage.vue"*/ '../../pages/AcceptTermsPage.vue'),
    loadingComponent: LoadingPlaceholder,
    errorComponent: ErrorPlaceholder,
  });

  export default defineComponent({
    components: { AcceptTermsPage },
    setup() {},
  });
</script>
