import { ref, shallowRef, watch } from 'vue';
import { MapIcon } from 'vue-tabler-icons';

import { MenuItemListTypes } from '@agritask/vue-components';
import { getTranslators } from '@agritask/vue-components/utils';

import paths from '../../router/paths';
import { buildMainMenuNav } from '../../utils/nav/navigation';

import useLocale from './useLocale';
import useUser from './useUser';

const useMainMenuItems = () => {
  const { permissions, logged, user } = useUser();
  const { loadedLocale } = useLocale();
  const menuItems = ref<MenuItemListTypes.MenuItem[]>([]);

  const { t } = getTranslators();

  watch(
    () => [permissions.value, logged.value, user.value?.role, loadedLocale.value],
    (args) => {
      const [permissions, logged, role] = args as [
        Permissions.ResponseData,
        boolean,
        Auth.UserRole
      ];

      if (logged) {
        menuItems.value = [
          { name: t('map.label'), icon: shallowRef(MapIcon), route: { to: { name: paths.HOME } } },
          ...buildMainMenuNav(permissions, role),
        ];
      } else {
        menuItems.value = [];
      }
    },
    { immediate: true }
  );

  return menuItems;
};

export default useMainMenuItems;
