import { App } from 'vue';

import { getTranslators } from '@agritask/vue-components/utils';

export default {
  install(app: App) {
    const trans = getTranslators('');

    app.config.globalProperties.$tc = (
      key: string,
      args: number | Object | unknown[] | undefined,
      values: Object | unknown[] | undefined
    ) => trans.t(key, args, values);
  },
};
