import { Component, shallowRef } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import {
  AdjustmentsAltIcon,
  BriefcaseIcon,
  BugIcon,
  FileCertificateIcon,
  IdIcon,
  Plant2Icon,
  SettingsIcon,
  ShapeIcon,
  ShieldCheckIcon,
  UsersIcon,
} from 'vue-tabler-icons';

import { MenuItemListTypes } from '@agritask/vue-components';
import { getTranslators } from '@agritask/vue-components/utils';

import paths from '../../router/paths';

export const buildMainMenuNav = (
  actionPermissions?: Permissions.ResponseData | null,
  role?: Auth.UserRole
): MenuItemListTypes.MenuItem[] => {
  if (actionPermissions && role) {
    const entitiesOperationsRecords = Object.entries(actionPermissions.operations);

    const { t } = getTranslators();

    const order = ['plots', 'insureds', 'policies', 'users'];

    const pageIcons: Record<string, Component> = {
      insureds: shallowRef(ShieldCheckIcon),
      plots: shallowRef(ShapeIcon),
      policies: shallowRef(FileCertificateIcon),
      users: shallowRef(UsersIcon),
    };

    const entityTypesWithRead = entitiesOperationsRecords.reduce(
      (acc, [entityType, entityOperations]) => {
        if (entityOperations.read) {
          acc[entityType] = true;
        }
        return acc;
      },
      {} as Record<string, boolean>
    );

    const menuItems = order.reduce<MenuItemListTypes.MenuItem[]>((acc, entityType) => {
      if (entityTypesWithRead[entityType]) {
        acc.push({
          name: t(`entities.${entityType}.label`),
          current: false,
          icon: pageIcons[entityType],
          route: {
            to: { name: paths.ENTITIES, params: { type: entityType } },
          },
        });
      }
      return acc;
    }, []);

    const createSettingMenuChildren = (): MenuItemListTypes.MenuItem[] => {
      const settingSubpageTypes: Entities.EntityType[] = ['brokers', 'crops', 'perils', 'projects'];
      const linkIconMap: Record<string, unknown> = {
        brokers: BriefcaseIcon,
        crops: Plant2Icon,
        perils: BugIcon,
        projects: IdIcon,
      };

      const items = settingSubpageTypes.map<MenuItemListTypes.MenuItem>((entityType) => {
        return {
          name: t(`entities.${entityType}.label`),
          current: false,
          icon: linkIconMap[entityType] || '',
          route: {
            to: { name: paths.SETTINGS_ENTITIES, params: { type: entityType } },
          },
        };
      });

      if (role === 'super_admin') {
        items.push({
          name: t('page_titles.configuration'),
          icon: shallowRef(AdjustmentsAltIcon),
          route: {
            to: { name: paths.SETTINGS },
          },
        });
      }

      return items;
    };

    const rolesWithSettings: Auth.UserRole[] = ['super_admin', 'admin', 'technician'];
    if (rolesWithSettings.includes(role)) {
      menuItems.push({
        name: t(`settings.label`),
        children: createSettingMenuChildren(),
        icon: shallowRef(SettingsIcon),
        route: {
          to: { name: paths.SETTINGS },
        },
      });
    }

    return menuItems;
  }
  return [];
};

export const setWindowTabName = (route: RouteLocationNormalized) => {
  const { t } = getTranslators();
  let nameParts = [t('app_title')];
  const { type } = route.params;

  const routeBuilders: Record<string, Function> = {
    [paths.SETTINGS]: () => nameParts.unshift(t('page_titles.settings')),
    [paths.ENTITIES]: () => nameParts.unshift(t(`entities.${type}.label`)),
    [paths.ENTITY]: () => nameParts.unshift(t(`entities.${type}.single`)),
    [paths.ENTITY_NEW]: () =>
      nameParts.unshift(t(`entities_actions.new`, 0, { type: t(`entities.${type}.single`) })),
    [paths.ENTITY_HISTORY]: () =>
      nameParts.unshift(t(`page_titles.history`, 0, { item: t(`entities.${type}.single`) })),
  };

  const routeBuilder = routeBuilders[route.name as string];
  routeBuilder && routeBuilder();

  if (nameParts[0] === 'app_title') {
    document.title = t('page_titles.default');
  } else {
    document.title = nameParts.map((str) => str[0].toUpperCase() + str.substring(1)).join(' | ');
  }
};
