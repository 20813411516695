<template>
  <Page />
</template>

<script lang="ts">
  import { defineAsyncComponent, defineComponent } from 'vue';
  import LoadingPlaceholder from '../../components/helpers/LoadingPlaceholder.vue';
  import ErrorPlaceholder from '../../components/helpers/ErrorPlaceholder.vue';

  const Page = defineAsyncComponent({
    loader: () => import(/*webpackChunkName: "PlotDataPage.vue"*/ '../../pages/PlotDataPage.vue'),
    loadingComponent: LoadingPlaceholder,
    errorComponent: ErrorPlaceholder,
  });

  export default defineComponent({
    components: { Page },
    setup() {},
  });
</script>
