import { Ref, computed, ref } from 'vue';
import { RouteLocation, RouteRecordName, useRoute, useRouter } from 'vue-router';

import paths from '../../router/paths';
import { PlotSearch } from '../main_dashboard/inject';

type MapPendingAction = 'create plot' | 'toggle plot list' | 'toggle statistics';

let pendingMapAction: Ref<MapPendingAction | null>;
export const BACK_ON_COMPLETE = '1';
export type BACK_QUERY_VALUES = typeof BACK_ON_COMPLETE;

const usePages = () => {
  const { push, resolve, back } = useRouter();
  pendingMapAction = pendingMapAction || ref(null);

  interface Route extends RouteLocation {
    params: { type?: string; id?: string };
  }
  const pagesWithoutSidebar: RouteRecordName[] = [paths.LOGIN, paths.ACCEPT_TERMS];
  const route = useRoute() as Route;

  const isInHomePage = computed(() => route.name === paths.HOME);
  const isInPageWithoutSidebar = computed(() => {
    if (route.name) {
      return pagesWithoutSidebar.includes(route.name);
    }
    return false;
  });

  const gotoTablePage = (type?: string) => {
    const entitiesPageName =
      route.name === paths.SETTINGS_ENTITY ? paths.SETTINGS_ENTITIES : paths.ENTITIES;

    type = type || (route.params.type as string);
    push({ name: entitiesPageName, params: { type } });
  };

  const gotoTableReviewPage = (job_id: number, type?: string) => {
    type = type || (route.params.type as string);
    push({ name: paths.ENTITIES_REVIEW, params: { type, job_id } });
  };

  const gotoEntityEditPage = (type?: string, id?: number | string, backAfterSave = false) => {
    type = type || (route.params.type as string);
    const query: Record<string, string> = {};
    if (backAfterSave) {
      query.back = BACK_ON_COMPLETE;
    }
    let entitiesPageName: string;
    if (!!id) {
      entitiesPageName = paths.ENTITY;
      if (typeof route.name === 'string' && route.name.includes('settings')) {
        entitiesPageName = paths.SETTINGS_ENTITY;
      }
      push({ name: paths.ENTITY, params: { type, id }, query });
    } else {
      entitiesPageName = paths.ENTITY_NEW;
      if (typeof route.name === 'string' && route.name.includes('settings')) {
        entitiesPageName = paths.SETTINGS_ENTITY_NEW;
      }
      push({ name: entitiesPageName, params: { type }, query });
    }
  };

  const gotoEntityHistoryPage = (id: number | string, type?: string) => {
    let entitiesPageName: string = paths.ENTITY_HISTORY;

    if (typeof route.name === 'string' && route.name.includes('settings')) {
      entitiesPageName = paths.SETTINGS_ENTITY_HISTORY;
    }

    type = type || (route.params.type as string);
    push({ name: entitiesPageName, params: { type, id } });
  };

  const gotoMapPage = (action: MapPendingAction | null = null) => {
    pendingMapAction.value = action;
    push({ name: paths.HOME });
  };

  const gotoPlotDataPage = (id: number) => {
    push({ name: paths.PLOT_DATA, params: { id } });
  };

  const gotoPolicyStatisticsPage = (plotsSearchInjection?: Ref<PlotSearch>) => {
    let plotSearch = '';
    if (plotsSearchInjection?.value.search) {
      plotSearch = JSON.stringify(plotsSearchInjection?.value.search);
    }
    push({
      name: paths.POLICIES_STATISTICS,
      query: { plotSearch, policySearch: '' }, // TODO Send string-object with filters of policy
    });
  };

  const gotoLoginPage = () => {
    push({ name: paths.LOGIN });
  };

  const refresh = () => {
    push({ name: paths.REFRESH });
  };

  return {
    push,
    back,
    refresh,
    resolve,
    isInHomePage,
    isInPageWithoutSidebar,
    gotoTablePage,
    gotoTableReviewPage,
    gotoEntityEditPage,
    gotoEntityHistoryPage,
    gotoMapPage,
    gotoPlotDataPage,
    pendingMapAction,
    gotoPolicyStatisticsPage,
    gotoLoginPage,
    route,
  };
};

export default usePages;
