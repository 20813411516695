<template>
  <span>failed to load</span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'ErrorPlaceholder',
    setup() {},
  });
</script>
