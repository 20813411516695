export const ENTITY_EDIT_PATH_SUFFIX = 'edit/';
export const ENTITY_NEW_PATH_SUFFIX = 'new/';
export const ENTITY_REVIEW_PATH_SUFFIX = 'review/';
export const ENTITY_HISTORY_PATH_SUFFIX = 'history/';
export const PLOT_DATA_SUFFIX = 'weather_history/';

const paths = Object.freeze({
  REFRESH: 'REFRESH',
  REFRESH_PATH: '/refresh',
  HOME: 'home',
  HOME_PATH: '/',
  LOGIN: 'login',
  LOGIN_PATH: '/login',
  SETTINGS: 'settings',
  SETTINGS_PATH: '/settings',
  // regular entity pages
  ENTITIES: 'entities',
  ENTITIES_REVIEW: 'entities_review',
  ENTITIES_PATH: '/item/:type/',
  ENTITIES_REVIEW_PATH: '/item_review/:type/:job_id',
  ENTITY: 'entity',
  ENTITY_NEW: 'entity new',
  ENTITY_REVIEW: 'entity_review',
  ENTITY_HISTORY: 'history',
  PLOT_DATA: 'plot data',
  // sub settings entity pages
  SETTINGS_ENTITIES: 'settings_item',
  SETTINGS_ENTITY: 'settings_entity',
  SETTINGS_ENTITY_NEW: 'settings_entity new',
  SETTINGS_ENTITY_HISTORY: 'settings_history',
  POLICIES_STATISTICS: 'policies_statistics',
  POLICIES_STATISTICS_PATH: '/policies_statistics',
  RESET_PASSWORD: 'reset_password',
  RESET_PASSWORD_PATH: '/reset_password',
  ACCEPT_TERMS: 'accept_terms',
  ACCEPT_TERMS_PATH: '/accept_terms',
  NOT_FOUND_404: 'not found 404',
});

export default paths;
