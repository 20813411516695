<template>
  <div class="notificationList">
    <LoadingPlaceholder v-if="isRefreshing" />
    <h4 v-else-if="notifications.length === 0" class="font-title-medium text-center">
      {{ $t('notifications.empty') }}
    </h4>
    <ul class="notificationList-list" v-else>
      <Notification
        :class="{ read: noti.read, unread: !noti.read }"
        v-for="noti in notifications"
        :key="noti.id"
        :notification="noti"
        @delete="deleteNotification(noti.id)"
        @pageChanging="$emit('closeSubpanel')"
      />
    </ul>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, onUnmounted } from 'vue';

  import LoadingPlaceholder from '@/comps/helpers/LoadingPlaceholder.vue';
  import useNotifications from '@/src/components/hooks/notifications/useNotifications';

  import Notification from './Notification.vue';

  export default defineComponent({
    name: 'NotificationList',
    components: { LoadingPlaceholder, Notification },
    emits: ['closeSubpanel'],
    setup() {
      const {
        notifications,
        isRefreshing,
        refreshNotification,
        deleteNotification,
        subtractAllUnreadsWithNoActionsFromUnreadCount,
      } = useNotifications();

      onMounted(refreshNotification);

      onUnmounted(() => {
        subtractAllUnreadsWithNoActionsFromUnreadCount();
      });

      return {
        isRefreshing,
        notifications,
        deleteNotification,
      };
    },
  });
</script>

<style lang="scss">
  .notificationList {
    &-list {
      padding: 0;
      list-style: none;
    }

    .read {
      background: var(--gray-200);
    }

    .unread {
      background: var(--white);
    }

    .Notification {
      margin-bottom: var(--spacing-04);
      padding: 8px;
      box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.1);
      border: 1px solid var(--gray-300);
      border-radius: 8px;
    }
  }
</style>
