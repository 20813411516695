import { Ref, computed, ref } from 'vue';

import {
  login,
  logout,
  requestResetPassword,
  setLocale,
  silentLogin,
  swapProject,
} from '../../api/auth';

import useLocale from './useLocale';
import usePage from './usePages';

let user: Ref<Auth.UserData | null>;
let permissions: Ref<Permissions.ResponseData | null>;
let logStatusChanging: Ref<boolean>;

export const isLogging = () => logStatusChanging && logStatusChanging.value;
export const isLogged = () => user && user.value;
export const isLoggedAsync = async () => {
  return new Promise((resolve) => {
    function attempLoginStateValidation() {
      if (logStatusChanging && !logStatusChanging.value) {
        resolve(user && user.value);
      } else {
        setTimeout(attempLoginStateValidation, 50);
      }
    }

    attempLoginStateValidation();
  });
};
export const getUser = () => user.value;

const useUser = () => {
  user = user || ref<Readonly<Auth.UserData | null>>(null);
  permissions = permissions || ref<Readonly<Permissions.ResponseData | null>>(null);
  logStatusChanging = logStatusChanging || ref(false);
  const { locale, localeFromFooter } = useLocale();
  const { refresh } = usePage();

  const logged = computed(() => !!user.value);

  const callLogin = async (email: string, password: string) => {
    logStatusChanging.value = true;
    const loginResponse = await login({ email, password });
    return postLoginAttempt(loginResponse);
  };

  const callLogout = async () => {
    logStatusChanging.value = true;
    await logout();
    user.value = null;
    permissions.value = null;
    logStatusChanging.value = false;
    return true;
  };

  const callRequestResetPassword = (email: string) => requestResetPassword({ email });

  const callSilentLogin = async () => {
    if (!window.was_logged) {
      return false;
    }

    logStatusChanging.value = true;
    const loginResponse = await silentLogin();
    return postLoginAttempt(loginResponse);
  };

  const callSwapProject = async (project_id: number) => {
    logStatusChanging.value = true;
    const result = await swapProject(project_id);
    if (result.ok) {
      user.value!.project_id = project_id;
      logStatusChanging.value = false;
      refresh();
    }
  };

  const postLoginAttempt = (loginResponse: IAPIResponse<Auth.AuthLoginDataExtended>) => {
    if (loginResponse.ok && loginResponse.data) {
      user.value = loginResponse.data.user;
      permissions.value = loginResponse.data.permissions;
      if (localeFromFooter.value) {
        locale.value = localeFromFooter.value;
        setLocale(localeFromFooter.value);
      } else {
        locale.value = loginResponse.data.user.locale;
      }
    } else {
      window.was_logged = false;
    }
    logStatusChanging.value = false;
    return user.value;
  };

  return {
    user,
    permissions,
    login: callLogin,
    logout: callLogout,
    silentLogin: callSilentLogin,
    swapProject: callSwapProject,
    requestResetPassword: callRequestResetPassword,
    logStatusChanging,
    logged,
  };
};

export default useUser;
