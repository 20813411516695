<template>
  <div
    class="MainLayout"
    :class="{
      'sidebar-mini': sidebarMini,
      'hide-footer': hideFooter,
      'hide-sidebar': hideSidebar,
    }"
  >
    <aside class="MainLayout-aside" v-if="!hideSidebar" :class="asideCls">
      <slot name="sidebar" />
      <div class="MainLayout-aside-bottom">
        <slot name="sidebar-bottom" />
      </div>
    </aside>
    <main class="MainLayout-main">
      <slot />
    </main>
    <footer v-if="!hideFooter" class="MainLayout-footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, toRefs } from 'vue';

  import useHideMobileHeader from './hooks/useHideMobileHeader';
  import './main_layout.scss';

  export default defineComponent({
    name: 'MainLayout',
    props: {
      sidebarMini: { type: Boolean, default: true },
      hideFooter: { type: Boolean, default: true },
      hideSidebar: { type: Boolean, default: false },
      mobileMenuOpen: { type: Boolean, default: false },
    },
    setup(props) {
      const { mobileMenuOpen } = toRefs(props);
      const { hideHeader } = useHideMobileHeader(mobileMenuOpen);

      const asideCls = computed(() => ({
        hidden: hideHeader.value,
      }));

      return { asideCls };
    },
  });
</script>
