import { computed, ref, Ref } from 'vue';

import { Plot } from '@/types/plot';
import { Insured } from '@/types/insured';
import { Policy } from '@/types/policy';

const SettedOnThisPage = 2;
const ToBeConsumedOnThisPage = 1;
let nextPageDataState: number = 0;

export interface DashboardPageData {
  plot?: Partial<Plot>;
}

export type EntityPageData = {
  users?: Entities.MinimalEntity[];
  crops?: Entities.MinimalEntity[];
  perils?: Entities.MinimalEntity[];
  brokers?: Entities.MinimalEntity[];
  insureds?: Insured[];
  plots?: Plot[];
  policies?: Policy[];
  projects?: Entities.MinimalEntity[];
};

export type DataForPage = undefined | DashboardPageData | EntityPageData;

let _nextPageData: Ref<DataForPage>;

/**
 * Should only be invoked on router.beforeEach once every page change
 * @returns
 */
export const updateCrossPageDataState = () => --nextPageDataState;

/**
 * Allows pages to pass data to the next page - the data will be persistent for 1 page change and will be set to `undefined` for the page after the next one - if no new value for `crossPageData` is set
 * @returns
 */
const useCrossPageData = <T extends DataForPage>() => {
  _nextPageData = _nextPageData || ref();

  const crossPageData = computed<T | undefined>({
    get: () => {
      if (nextPageDataState === ToBeConsumedOnThisPage) {
        return _nextPageData.value as T;
      }
      return undefined;
    },
    set: (value: T | undefined) => {
      nextPageDataState = SettedOnThisPage;
      _nextPageData.value = value;
    },
  });

  return { crossPageData };
};

export default useCrossPageData;
