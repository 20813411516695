<template>
  <AppLayout v-if="silentLoginCompleted" />
  <LoadingPlaceholder v-else class="app-loader" :text="'loading application'" />
</template>

<script lang="ts">
  import { defineComponent, onMounted, provide, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';

  import { GlobalStaticLabelFlag } from '@agritask/vue-components/hooks';

  import { setLocale } from './api/auth';
  import AppLayout from './components/AppLayout.vue';
  import LoadingPlaceholder from './components/helpers/LoadingPlaceholder.vue';
  import useUser from './components/hooks/useUser';
  import { setI18nLanguage } from './modules/i18n';

  export default defineComponent({
    name: 'App',
    components: { AppLayout, LoadingPlaceholder },
    setup() {
      setI18nLanguage();
      const { locale } = useI18n();
      const { silentLogin, logged } = useUser();
      const silentLoginCompleted = ref(false);

      watch(locale, (locale, prevLocale) => {
        setI18nLanguage(locale);
        if (prevLocale !== locale && logged.value) {
          setLocale(locale);
        }
      });

      // all inputs render label on top of input
      provide(GlobalStaticLabelFlag, true);

      onMounted(async () => {
        await silentLogin();
        silentLoginCompleted.value = true;
      });

      return {
        silentLoginCompleted,
      };
    },
  });
</script>

<style scoped>
  .logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
  }
  .logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
  }
  .logo.vue:hover {
    filter: drop-shadow(0 0 2em #42b883aa);
  }

  .app-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    text-transform: uppercase;
    font-size: 2rem;
  }
</style>
