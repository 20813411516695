import { fetchGet, fetchWithPayload } from '../utils';

/**
 * Fetch the number of unread messages the user has in this project
 * @returns Promise<false | number> count of unread notifications
 */
export const fetchUnreadCount = async () => {
  return fetchGet<IAPIResponse<{ count: number }>>(`/api/notifications/unread/count`).then(
    (res) => {
      return res.ok && (res.data as unknown as { count: number }).count;
    }
  );
};

/**
 * Marks a notification as "read" by sending a PUT request to the API.
 * @param {number} id - The ID of the notification to be marked as "read".
 * @throws {Error} Throws an error if the fetch request fails.
 * @returns {Promise<Response>} A Promise that resolves to the HTTP response.
 */
export const sendMarkAsRead = async (ids: number[]) =>
  fetchWithPayload(`/api/notifications/mark-read`, 'PUT', { ids });
