import { computed } from 'vue';

import { getTranslators } from '@agritask/vue-components/utils';

import { PrettyPrefix } from '@/utils/number';

/**
 * Returns a vue's Computed containing localized number prefixes for millions and thousands.
 * @function useLocalizedNumberPrefixes
 * @returns {Object} An object containing the localized number prefixes.
 */
export default function useLocalizedNumberPrefixes() {
  const { t } = getTranslators('unit');

  const numerPrefixes = computed<PrettyPrefix>(() => ({
    millions: t('millions'),
    thousands: t('thousands'),
  }));

  return numerPrefixes;
}

/**
 * Returns an object containing localized number prefixes for millions and thousands.
 * @returns {Object} An object containing the localized number prefixes.
 */
export const getNumberPrefix = (): PrettyPrefix => {
  const { t } = getTranslators('unit');

  return {
    millions: t('millions'),
    thousands: t('thousands'),
  };
};
