<template>
  <div class="LayoutFooter">
    <div class="LayoutFooter-start">
      <slot name="start" />
    </div>
    <div class="LayoutFooter-center">
      <slot />
    </div>
    <div class="LayoutFooter-end">
      <slot name="end" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  import './layout_footer.scss';

  export default defineComponent({
    name: 'LayoutFooter',
    setup() {
      return {
        version: window.web_api_version || '?',
      };
    },
  });
</script>
