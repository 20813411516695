import { ref, useSlots } from 'vue';

import { useInterval } from '@agritask/vue-components/hooks';

/**
 * Check if a child slot is apssed or not
 * @param {string} slotName The name of the slot to check
 * @param intervalMS The interval to check
 * @returns
 */
export default function useSlotExist(slotName: string, intervalMS = 150) {
  const slots = useSlots();
  const { interval } = useInterval();

  const checkIfExits = () => {
    const slot = slots[slotName] && slots[slotName]!();
    return !!slot && slot.length > 0;
  };

  const exists = ref(checkIfExits());

  interval(() => {
    exists.value = checkIfExits();
  }, intervalMS);

  return exists;
}
