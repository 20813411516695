import { Ref, ref, computed, onUnmounted } from 'vue';

let hasPlotSelected: Ref<boolean>;
let plotListOpen: Ref<boolean>;

/**
 * Should be used by AppLayout or other components (that is NOT a under the MainDashboard tree) that needs to know something about the MainDashboard state
 * Descendents of MainDashboard should not use this hook
 * @returns
 */
const useMainDashboardState = () => {
  hasPlotSelected = hasPlotSelected || ref(false);
  plotListOpen = plotListOpen || ref(false);
  const computerdHasPlotSelected = computed(() => hasPlotSelected.value);
  const computerdPlotListOpen = computed(() => plotListOpen.value);

  return {
    hasPlotSelected: computerdHasPlotSelected,
    plotListOpen: computerdPlotListOpen,
  };
};

/**
 * For use by MainDashboard only
 * @returns
 */
export const useMainDashboardStateWriter = () => {
  hasPlotSelected = hasPlotSelected || ref(false);
  plotListOpen = plotListOpen || ref(false);

  onUnmounted(() => {
    hasPlotSelected.value = false;
  });

  return {
    hasPlotSelected,
    plotListOpen,
  };
};

export default useMainDashboardState;
