import { Ref, isRef } from 'vue';

export type MaybeRef<T> = Ref<T> | T;

/**
 * Returns the value of a MaybeRef (a Vue Ref<T> or a T)
 * @template T
 * @param {MaybeRef<T>} refOrValue - A Ref or a value.
 * @return {T} The value of MaybeRef.
 */
export const getMaybeRefValue = <T>(refOrValue: MaybeRef<T>) => {
  return isRef(refOrValue) ? refOrValue.value : refOrValue;
};
