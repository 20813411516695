import { fetchGet, fetchWithPayload } from '../utils';

/**
 * Sends a request to attemp a login for the user.
 * @param {Auth.LoginRequestBody} body - The request body with `email` & `password` params
 * @return {Promise<Auth.AuthLoginData>} - A promise that resolves to the response from the server.
 */
export const login = (body: Auth.LoginRequestBody) =>
  fetchWithPayload<Auth.AuthLoginDataExtended>('/api/auth/login', 'PUT', body);

/**
 * Sends a request to attemp a login for the user that might already have an active logic record (sent after app loaded for returned user).
 * @return {Promise<Auth.AuthLoginData>} - A promise that resolves to the response from the server.
 */
export const silentLogin = () =>
  fetchWithPayload<Auth.AuthLoginDataExtended>('/api/auth/silent_login', 'PUT');

/**
 * Sends a logout request.
 * @return {Promise<IAPIResponse<any>>} - A promise that resolves to the response from the server.
 */
export const logout = () => fetchWithPayload('/api/user/logout', 'PUT');

/**
 * Requests a password reset for the given email.
 * @param {Object} body - The request body.
 * @param {string} body.email - The email for which to request a password reset.
 * @returns {Promise} A promise that resolves to the result of the request.
 */
export const requestResetPassword = (body: { email: string }) =>
  fetchWithPayload(`/api/auth/request_reset_password`, 'PUT', body);

/**
 * Resets the password for the given email using the provided password and token.
 * @param {Object} body - The request body.
 * @param {string} body.email - The email associated with the password reset.
 * @param {string} body.password - The new password for the account.
 * @param {string} body.token - The token received for the password reset.
 * @returns {Promise} A promise that resolves to the result of the reset request.
 */
export const resetPassword = (body: { email: string; password: string; token: string }) =>
  fetchWithPayload(`/api/auth/reset_password`, 'PUT', body);

/**
 * Fetches the reset password state by making a GET request to the specified endpoint.
 * @param {string} email - The email parameter for the reset password request.
 * @param {string} token - The token parameter for the reset password request.
 * @returns {Promise<Object>} The response object containing the reset password state.
 */
export const resetPasswordState = (email: string, token: string) =>
  fetchGet(`/api/auth/reset_password`, {
    email,
    token,
  });

/**
 * Sends a request to change the user's locale.
 * @param {string} locale - The user's new locale
 * @return {Promise<boolean>} - A promise that resolves to the response from the server.
 */
export const setLocale = (locale: string) =>
  fetchWithPayload<boolean>(`/api/users/swap_locale?locale=${locale}`, 'PUT');

/**
 * Sends a request to change the current project for the user.
 * @param {number} project_id - The project ID to include in the query string.
 * @return {Promise<IAPIResponse<any>>} - A promise that resolves to the response from the server.
 */
export const swapProject = (project_id: number) =>
  fetchWithPayload(`/api/users/swap_project?project_id=${project_id}`, 'PUT', {});
