import { Ref, computed, reactive, watch } from 'vue';

import { getTranslators } from '@agritask/vue-components/utils';

import { fetchPolicyStatistics } from '@/src/api/entities/policies';
import { prettyNumber } from '@/src/utils/number';
import { structuredCloneObject } from '@/src/utils/object';

import useLocalizedNumberPrefixes from '../../hooks/useLocalizedNumberPrefixes';
import { PlotSearch } from '../../main_dashboard/inject';

/**
 * Fetches policy statistics based on the provided search parameters.
 * @param {Ref<PlotSearch>} [plotsSearchInjection] - Optional reference to the PlotSearch object.
 * @returns {Object} An object containing the computed property `dataBlocks`.
 */
export default function useFetchPolicyStatistics(plotsSearchInjection?: Ref<PlotSearch>) {
  const numberPrefixes = useLocalizedNumberPrefixes();
  const { t } = getTranslators();

  const dataBlocksValues = reactive({
    plots: 0,
    insureds: 0,
    policies: 0,
    sumInsured: 0,
    area: 0,
  });

  const fetchStatistics = async (search: Object) => {
    const policyStatistics = await fetchPolicyStatistics(search);
    if (policyStatistics.ok) {
      dataBlocksValues.plots = policyStatistics.data?.totalPlots || 0;
      dataBlocksValues.area = policyStatistics.data?.insuredArea || 0;
      dataBlocksValues.insureds = policyStatistics.data?.totalInsureds || 0;
      dataBlocksValues.policies = policyStatistics.data?.totalActivePolicies || 0;
      dataBlocksValues.sumInsured = policyStatistics.data?.sumInsured || 0;
    }
  };

  const dataBlocks = computed(() => [
    {
      label: t('entities.plots.label'),
      value: prettyNumber(dataBlocksValues.plots, numberPrefixes.value),
    },
    {
      label: t('entities.insureds.label'),
      value: prettyNumber(dataBlocksValues.insureds, numberPrefixes.value),
    },
    {
      label: t('map.statistics.active_policies'),
      value: prettyNumber(dataBlocksValues.policies, numberPrefixes.value),
    },
    {
      label: t('map.statistics.sum_insred'),
      value:
        prettyNumber(dataBlocksValues.sumInsured, numberPrefixes.value) + t('currency.usd.symbol'),
    },
    {
      label: t('map.statistics.insured_area'),
      value: prettyNumber(dataBlocksValues.area, numberPrefixes.value) + t('unit.ha'),
    },
  ]);

  watch(
    () => plotsSearchInjection,
    (searchInjection) => {
      if (searchInjection) {
        const plotSearch: Record<string, any> = structuredCloneObject(searchInjection.value.search);
        const policySearch: Record<string, any> = {};

        const policyEntries = Object.entries(plotSearch).filter(([key]) =>
          key.startsWith('policy.')
        );

        // split search entries to plot and policy search values
        policyEntries.forEach(([key, value]) => {
          delete plotSearch[key];
          const [_, policyKey] = key.split('.');
          policySearch[policyKey] = value;
        });

        const search = { plot: plotSearch, policy: policySearch };
        fetchStatistics(search);
      }
    },
    { deep: true, immediate: true }
  );

  return { dataBlocks };
}
