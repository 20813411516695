import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue';

/**
 * detecting the current media breakpoint hook.
 * @returns {object} The media properties.
 * @property {ref<boolean>} isMobile - A reactive reference to indicate if the current media is mobile.
 * @property {ref<boolean>} isDesktop - A reactive reference to indicate if the current media is desktop.
 */
export default function useBreakPoint() {
  const MOBILE_WIDTH = 600;

  const isMobile = ref(false);
  const isDesktop = ref(false);
  const onResize = () => {
    isMobile.value = window.innerWidth <= MOBILE_WIDTH;
    isDesktop.value = window.innerWidth > MOBILE_WIDTH;
  };

  onBeforeMount(onResize);

  onMounted(() => window.addEventListener('resize', onResize));
  onUnmounted(() => window.removeEventListener('resize', onResize));

  return {
    isMobile,
    isDesktop,
  };
}
