<template>
  <div class="UserActions">
    <!-- projects -->
    <NavCollapsable :options="projectOptions" v-model="selectedProjectId">
      <template #icon>
        <WorldIcon :size="20" />
      </template>
      {{ $t('entities.projects.label') }}
    </NavCollapsable>
    <!-- language -->
    <LocaleSelector class="w-100" v-model="locale" :localeOptions="localeOptions" />
    <!-- logout -->
    <NavLinkLike @click="$emit('logout')">
      <template #icon>
        <LogoutIcon :size="20" />
      </template>
      {{ $t('account.logout') }}
    </NavLinkLike>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { LogoutIcon, WorldIcon } from 'vue-tabler-icons';

  import { AtSelect, AtSelectTypes } from '@agritask/vue-components';
  import { getTranslators } from '@agritask/vue-components/utils';

  import { locales } from '@/src/modules/i18n';

  import LocaleSelector from './LocaleSelector.vue';
  import NavCollapsable from './NavCollapsable.vue';
  import NavLinkLike from './NavLinkLike.vue';

  export default defineComponent({
    name: 'UserActions',
    components: {
      AtSelect,
      LocaleSelector,
      NavLinkLike,
      NavCollapsable,
      LogoutIcon,
      WorldIcon,
    },
    props: {
      projectOptions: { type: Array, default: () => [] },
      projectId: { type: Number, default: 0 },
    },
    emits: ['logout', 'update:projectId', 'changeProjectId'],
    setup(props, { emit }) {
      // --- locale ---
      const { locale } = useI18n();
      const { t } = getTranslators();

      const localeOptions: AtSelectTypes.Option[] = locales.map((value) => ({
        value,
        text: t('locales.' + value),
      }));

      // --- project ---
      const selectedProjectId = computed<number>({
        get: () => props.projectId,
        set: (value) => {
          emit('update:projectId', value);
          emit('changeProjectId', value);
        },
      });

      return { locale, localeOptions, selectedProjectId };
    },
  });
</script>

<style lang="scss">
  .UserActions {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-04);
  }
</style>
