import { App } from 'vue';
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

import {
  showStopEditingEntityPopup,
  showStopGeneralEditingPopup,
} from '../components/hooks/useConfirmStopEditingPopup';
import { updateCrossPageDataState } from '../components/hooks/useCrossPageData';
import { getAccessToGlobalDirty } from '../components/hooks/useGlobalDirtyRegistry';
import { getUser, isLogged, isLoggedAsync, isLogging } from '../components/hooks/useUser';
import RefreshPage from '../pages/RefreshPage.vue';
import { setWindowTabName } from '../utils/nav/navigation';

import AcceptTermsWrapper from './page_wrappers/AcceptTermsWrapper.vue';
import ContainerPage from './page_wrappers/ContainerPageWrapper.vue';
import EntitiesPageWrapper from './page_wrappers/EntitiesPageWrapper.vue';
import EntityHistoryWrapper from './page_wrappers/EntityHistoryPageWrapper.vue';
import EntityPageWrapper from './page_wrappers/EntityPageWrapper.vue';
import HomePageWrapper from './page_wrappers/HomePageWrapper.vue';
import LoginPageWrapper from './page_wrappers/LoginPageWrapper.vue';
import PageNotFound from './page_wrappers/NotFound404Wrapper.vue';
import PlotDataPageWrapper from './page_wrappers/PlotDataPageWrapper.vue';
import PoliciesStatisticsWrapper from './page_wrappers/PoliciesStatisticsWrapper.vue';
import ResetPasswordWrapper from './page_wrappers/ResetPasswordWrapper.vue';
import SettingsPageWrapper from './page_wrappers/SettingsPageWrapper.vue';
import paths, {
  ENTITY_EDIT_PATH_SUFFIX,
  ENTITY_HISTORY_PATH_SUFFIX,
  ENTITY_NEW_PATH_SUFFIX,
  ENTITY_REVIEW_PATH_SUFFIX,
  PLOT_DATA_SUFFIX,
} from './paths';

// whitelist of paths allowed for not logged users to enter
const NOT_LOGGED_WHITELIST_PATHS: string[] = [paths.LOGIN_PATH, paths.RESET_PASSWORD_PATH];

export const assignRouter = (app: App) => {
  const routes: Readonly<RouteRecordRaw[]> = [
    { name: paths.REFRESH, path: paths.REFRESH_PATH, component: RefreshPage },
    { name: paths.HOME, path: paths.HOME_PATH, component: HomePageWrapper },
    { name: paths.LOGIN, path: paths.LOGIN_PATH, component: LoginPageWrapper },
    // regular entity pages
    {
      path: paths.ENTITIES_REVIEW_PATH,
      component: ContainerPage,
      children: [
        {
          name: paths.ENTITIES_REVIEW,
          path: '',
          component: EntitiesPageWrapper,
        },
        {
          name: paths.ENTITY_REVIEW,
          path: `:id/${ENTITY_REVIEW_PATH_SUFFIX}`,
          component: EntityPageWrapper,
        },
      ],
    },
    {
      path: paths.ENTITIES_PATH,
      component: ContainerPage,
      children: [
        {
          name: paths.ENTITIES,
          path: '',
          component: EntitiesPageWrapper,
        },
        {
          name: paths.ENTITY,
          path: `:id/${ENTITY_EDIT_PATH_SUFFIX}`,
          component: EntityPageWrapper,
        },
        {
          name: paths.ENTITY_NEW,
          path: ENTITY_NEW_PATH_SUFFIX,
          component: EntityPageWrapper,
        },
        {
          name: paths.ENTITY_HISTORY,
          path: `:id/${ENTITY_HISTORY_PATH_SUFFIX}`,
          component: EntityHistoryWrapper,
        },
        { name: paths.PLOT_DATA, path: `:id/${PLOT_DATA_SUFFIX}`, component: PlotDataPageWrapper },
      ],
    },
    // sub settings entity pages
    {
      path: paths.SETTINGS_PATH,
      component: ContainerPage,
      children: [
        {
          name: paths.SETTINGS,
          path: '',
          component: SettingsPageWrapper,
        },
        {
          name: paths.SETTINGS_ENTITIES,
          path: 'item/:type/',
          component: EntitiesPageWrapper,
        },
        {
          name: paths.SETTINGS_ENTITY,
          path: `item/:type/:id/${ENTITY_EDIT_PATH_SUFFIX}`,
          component: EntityPageWrapper,
        },
        {
          name: paths.SETTINGS_ENTITY_NEW,
          path: `item/:type/${ENTITY_NEW_PATH_SUFFIX}`,
          component: EntityPageWrapper,
        },
        {
          name: paths.SETTINGS_ENTITY_HISTORY,
          path: `item/:type/:id/${ENTITY_HISTORY_PATH_SUFFIX}`,
          component: EntityHistoryWrapper,
        },
      ],
    },
    // other pages
    {
      name: paths.POLICIES_STATISTICS,
      path: paths.POLICIES_STATISTICS_PATH,
      component: PoliciesStatisticsWrapper,
    },
    {
      name: paths.RESET_PASSWORD,
      path: paths.RESET_PASSWORD_PATH,
      component: ResetPasswordWrapper,
    },
    {
      name: paths.ACCEPT_TERMS,
      path: paths.ACCEPT_TERMS_PATH,
      component: AcceptTermsWrapper,
    },
    { path: '/:pathMatch(.*)', name: paths.NOT_FOUND_404, component: PageNotFound },
  ];

  const router = createRouter({
    routes,
    history: createWebHistory(),
  });

  app.use(router);

  const { getIsGlobalDirty, unDirtyAll, getDirtyEntity } = getAccessToGlobalDirty();

  router.beforeEach(async (to) => {
    updateCrossPageDataState();
    const logged = isLogging() ? await isLoggedAsync() : isLogged();
    const user = getUser();

    if (getIsGlobalDirty()) {
      // if there is an entity in editing...
      const dirtyEntity = getDirtyEntity();
      const onConfirmAbort = () => {
        unDirtyAll();
        router.push(to);
      };
      if (dirtyEntity) {
        showStopEditingEntityPopup(dirtyEntity, onConfirmAbort);
      } else {
        showStopGeneralEditingPopup(onConfirmAbort);
      }
      return false;
    }

    if (!logged && !NOT_LOGGED_WHITELIST_PATHS.includes(to.path)) {
      // unlogged users will be redirected to login page when attempting to enter restricted pages
      return { name: paths.LOGIN };
    } else if (logged && to.name !== paths.ACCEPT_TERMS) {
      if (!user?.terms_accepted) {
        return { name: paths.ACCEPT_TERMS };
      } else if (to.name === paths.LOGIN || to.name === paths.RESET_PASSWORD) {
        return { name: paths.HOME };
      }
    }
  });

  router.afterEach(setWindowTabName);

  return router;
};
