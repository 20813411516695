import { Ref } from 'vue';

import { AtPopupTypes } from '@agritask/vue-components';
import { useAtPopup } from '@agritask/vue-components/hooks';

import { getMaybeRefValue } from '@/src/utils/vue/reactives';

type VoidFunc = () => void;
type ShowPopup = (
  popupAppearance: AtPopupTypes.Appearance,
  translationPath: string,
  confirmCallback?: () => void,
  closeCallback?: () => void,
  titleText?: string,
  contentText?: string,
  confirmText?: string,
  cancelText?: string,
  closeText?: string
) => void;

let _atPopupProps: Ref<AtPopupTypes.AtPopupHookProps>;
let _showPopup: ShowPopup;

/**
 * Hook for invoking "confirm action" popup
 * @returns
 */
const useConfirmPopup = () => {
  return {
    showConfirm: showConfirmPopup,
  };
};

export const showConfirmPopup = (
  popupProps: AtPopupTypes.AtPopupHookProps,
  confirmCB: Function,
  closeCB?: Function
) => {
  if (_atPopupProps && _showPopup) {
    const popupPropsValue = { ..._atPopupProps.value, ...getMaybeRefValue(popupProps) };

    _showPopup(
      popupPropsValue.appearance,
      popupPropsValue.translationPath,
      confirmCB as VoidFunc,
      closeCB as VoidFunc,
      popupPropsValue.titleText,
      popupPropsValue.contentText,
      popupPropsValue.confirmText,
      popupPropsValue.cancelText,
      popupPropsValue.closeText
    );
  }
};

/**
 * Registering the "confirm action" AtPopup
 * @returns For internal AppLayout use
 */
export const useRegisterConfirmPopup = () => {
  if (_atPopupProps) {
    throw '[useRegisterConfirmPopup] already registered';
  }

  const { showPopup, atPopupProps, atPopupListeners } = useAtPopup();

  _atPopupProps = atPopupProps;
  _showPopup = showPopup;

  return {
    atPopupProps,
    atPopupListeners,
  };
};

export default useConfirmPopup;
