<template>
  <slot />
  <UserWidget
    v-if="logged"
    :mini="mini"
    :name="userName"
    :role="userRole"
    @logout="$emit('logout')"
    @click="$emit('userClick')"
    class="pointer"
  />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  import { UserWidget } from '@agritask/vue-components';

  export default defineComponent({
    name: 'SidebarBottom',
    components: { UserWidget },
    props: {
      mini: { type: Boolean, default: true },
      logged: { type: Boolean, default: false },
      userName: { type: String, default: '' },
      userRole: { type: String, default: '' },
    },
    emits: ['logout', 'userClick'],
    setup() {
      return {};
    },
  });
</script>

<style lang="scss">
  .UserWidget {
    margin-inline-start: auto;
    margin-inline-end: auto;

    .logout {
      display: none;
    }
  }
</style>
