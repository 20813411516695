<template>
  <router-view></router-view>
</template>

<script lang="ts">
  import { defineAsyncComponent, defineComponent } from 'vue';

  const ContainerPage = defineAsyncComponent({
    loader: () => import(/*webpackChunkName: "SettingsPage.vue"*/ '../../pages/SettingsPage.vue'),
  });

  export default defineComponent({
    components: { ContainerPage },
    setup() {},
  });
</script>
