import { isProxy, isReactive, isRef, toRaw, unref } from 'vue';

import { deepClone } from '@agritask/js-utils/libs/object_utils';

/**
 * Change all undefined values to null. usefull for AJAX requests' body that remove keys with value = undefined
 * @param {any} obj The object to modify
 * @param {boolean=true} deep When true - update nested objects
 */
export const covertUndefinedToNull = (obj: any, deep = true) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value === undefined) {
      obj[key] = null;
    } else if (deep && value !== null && typeof value === 'object') {
      covertUndefinedToNull(value);
    }
  }
};

/**
 * Create a new array of shallow clones
 * @param {Object[]} arr
 * @returns {Object[]} an Array of clones
 */
export const shallowCloneObjectArray = <T extends Object>(arr: T[]) =>
  arr.map((obj) => ({ ...obj }));

/**
 * Deep clone an object using structuredClone, including vue Ref object after unrefing
 * @param object
 * @returns
 */
export const structuredCloneObject = <T>(object: T): T => {
  object = isReactive(object) ? toRaw(object) : object;
  try {
    return structuredClone(object) as T;
  } catch {
    return deepClone(object);
  }
};
