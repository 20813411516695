import { onBeforeUnmount, ref, watch } from 'vue';

import EventEmitter from '@/src/utils/events/EventEmitter';
import { incrementKeysCounter } from '@/src/utils/object/increment_keys_counter';

const dirtyIdentifiersMap: Map<string, number> = new Map();
const identifierToEntityMap: Record<string, Entities.EntityType> = {};
let eventEmitter: EventEmitter<{ dirtyChange: boolean; clearAll: undefined }>;
let wasDirty = false;

/**
 * Vue hook that exposes functionality to mark elements as dirty.
 * When changing a page, if there are elements marked as dirty, the user will require to confirm the page change.
 * @param {string} identifier A string that identifies the element that can be marked as dirty
 * @param {[Entities.EntityType]} associatedEntity Optional Entities.EntityType that is associted with the identifier and used in the confirm abort message
 * @returns
 */
export default function useGlobalDirtyRegistry(
  identifier: string,
  associatedEntity?: Entities.EntityType
) {
  init();

  if (associatedEntity) {
    identifierToEntityMap[identifier] = associatedEntity;
  }

  const isDirty = ref(false);
  let clearAllTriggered = false;

  watch(isDirty, (isDirty) => {
    identifierDirtyChange(isDirty, !clearAllTriggered);
    clearAllTriggered = false;
  });

  // update the
  function identifierDirtyChange(isDirty: boolean, emit = true) {
    incrementKeysCounter(identifier, dirtyIdentifiersMap, isDirty ? 1 : -1);

    let isGlobalDirty = false;
    for (const val of dirtyIdentifiersMap.values()) {
      if (val > 0) {
        isGlobalDirty = true;
        break;
      }
    }
    if (emit && isGlobalDirty !== wasDirty) {
      eventEmitter.emit('dirtyChange', isGlobalDirty);
    }
    wasDirty = isGlobalDirty;
  }

  const onUndirtyAll = () => {
    if (isDirty) {
      clearAllTriggered = true;
      isDirty.value = false;
    }
  };

  eventEmitter.addEventListener('clearAll', onUndirtyAll);

  onBeforeUnmount(() => {
    eventEmitter.removeEventListener('clearAll', onUndirtyAll);
  });

  return {
    isDirty,
    unDirtyAll,
    updateIsDirty: (dirty: boolean) => (isDirty.value = dirty),
    changeAssociatedEntity: (entityType: Entities.EntityType) =>
      (identifierToEntityMap[identifier] = entityType),
  };
}

/**
 * initiats required elements
 */
const init = () => {
  if (!eventEmitter) {
    eventEmitter = new EventEmitter();
  }
};

/**
 * Mark all existing dirty identifiers as undirty.
 * Usually called when the user confirms the page change
 */
function unDirtyAll() {
  dirtyIdentifiersMap.clear();
  eventEmitter.emit('clearAll', undefined);
}

/**
 * Exoses functionalities associated with the global dirty registry
 * @returns functions
 */
export function getAccessToGlobalDirty() {
  init();

  function getDirtyEntity(): Entities.EntityType | undefined {
    if (dirtyIdentifiersMap.size > 1) {
      return undefined;
    }
    const firstDirtyKey = dirtyIdentifiersMap.keys().next().value as string | undefined;
    return firstDirtyKey ? identifierToEntityMap[firstDirtyKey] : undefined;
  }

  return {
    eventEmitter,
    unDirtyAll,
    getDirtyEntity,
    getIsGlobalDirty: () => wasDirty,
  };
}
