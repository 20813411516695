import { Ref, ref } from 'vue';

let isShowFileUpload: Ref<boolean>;
let isFileUploading: Ref<boolean>;
let entityType: Ref<Entities.EntityType | null>;

export default function useShowEntitiesUpload() {
  if (!isShowFileUpload) {
    isShowFileUpload = ref(false);
    isFileUploading = ref(false);
    entityType = ref(null);
  }

  const show = (type: Entities.EntityType) => {
    isShowFileUpload.value = true;
    entityType.value = type;
  };

  const close = () => (isShowFileUpload.value = false);

  return { isShowFileUpload, isFileUploading, show, close, entityType };
}
