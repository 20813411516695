<template>
  <div class="LayoutSidebar" :class="cssClasses" ref="rootRef">
    <div class="LayoutSidebar-content" v-if="isRenderContentDisplay">
      <div class="LayoutSidebar-header">
        <router-link :to="homeRoute" class="logo-a">
          <img v-if="logo_url" :src="logo" class="logo" alt="Agritrask RMS" />
        </router-link>
        <button class="toggle" @click="toggleClick">
          <ChevronLeftIcon v-if="isDesktop" />
          <Menu2Icon v-if="isMobile" />
        </button>
      </div>
      <div class="LayoutSidebar-subcontent" v-if="renderSubcontent">
        <MenuItemList
          v-if="items.length > 0"
          data-testid="main-menu"
          :items="items"
          :mini="renderMiniVersion"
        />
        <div class="postmenu-wrapper">
          <slot name="postmenu" />
        </div>
        <div class="LayoutSidebar-bottom">
          <SidebarBottom
            :mini="renderMiniVersion"
            :logged="logged"
            :userName="userName"
            :userRole="userRole"
            @logout="$emit('logout')"
            @userClick="$emit('toggleUser')"
          >
            <slot name="bottom" />
          </SidebarBottom>
        </div>
      </div>
    </div>
    <div class="LayoutSidebar-subpanel styled-scroll" v-if="hasSubpanel">
      <slot name="subpanel"></slot>
    </div>
  </div>
</template>

<script lang="ts">
  import { PropType, computed, defineComponent, ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { ChevronLeftIcon, Menu2Icon } from 'vue-tabler-icons';

  import { AtSelect, MenuItemList, MenuItemListTypes } from '@agritask/vue-components';
  import { useClickOutside } from '@agritask/vue-components/hooks';
  import { CollapsableMenuItem } from '@agritask/vue-components/src/js/vue/navbar/types';

  import useBreakPoint from '@/hooks/useBreakPoint';
  import useSlotExists from '@/hooks/useSlotExists';
  import paths from '@/src/router/paths';

  import SidebarBottom from '../sidebar_bottom/SidebarBottom.vue';

  import './layout-sidebar.scss';

  export default defineComponent({
    name: 'LayoutSidebar',
    components: { MenuItemList, ChevronLeftIcon, Menu2Icon, AtSelect, SidebarBottom },
    props: {
      logo_url: { type: String, required: false },
      mini_logo_url: { type: String, required: false },
      items: {
        type: Array as PropType<(MenuItemListTypes.MenuItem | CollapsableMenuItem)[]>,
        default: () => [],
      },
      mini: { type: Boolean, default: false },
      logged: { type: Boolean, default: false },
      userName: { type: String, default: '' },
      userRole: { type: String, default: '' },
    },
    emits: [
      'toggleSidebar',
      'update:projectId',
      'changeProjectId',
      'logout',
      'mobileMenuOpen',
      'closeSubpanelClick',
      'toggleUser',
    ],
    setup(props, { emit }) {
      const { afterEach } = useRouter();
      const { isDesktop, isMobile } = useBreakPoint();

      const rootRef = ref<HTMLElement>();

      const emitCloseSubpanelIfOpen = () => {
        if (hasSubpanel.value) {
          emit('closeSubpanelClick');
        }
      };

      useClickOutside(rootRef, emitCloseSubpanelIfOpen);

      const cssClasses = computed(() => ({
        mini: props.mini,
      }));

      const logo = computed(() => {
        if (isDesktop.value && props.mini) {
          return props.mini_logo_url || props.logo_url;
        }
        return props.logo_url || props.mini_logo_url;
      });

      const homeRoute = { name: paths.HOME };

      const mobileMenuOpen = ref(false);

      const renderSubcontent = computed(() => isDesktop.value || mobileMenuOpen.value);

      const renderMiniVersion = computed(() => props.mini && isDesktop.value);

      const toggleClick = () => {
        if (isDesktop.value) {
          emit('toggleSidebar');
        } else {
          mobileMenuOpen.value = !mobileMenuOpen.value;
        }
      };

      afterEach(() => {
        emitCloseSubpanelIfOpen();
        mobileMenuOpen.value = false;
      });

      watch(mobileMenuOpen, (open) => emit('mobileMenuOpen', open));

      const hasSubpanel = useSlotExists('subpanel');

      const isRenderContentDisplay = computed(() => !(hasSubpanel.value && isMobile.value));

      return {
        cssClasses,
        logo,
        homeRoute,
        isDesktop,
        isMobile,
        renderMiniVersion,
        renderSubcontent,
        isRenderContentDisplay,
        toggleClick,
        hasSubpanel,
        rootRef,
      };
    },
  });
</script>
