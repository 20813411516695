<template>
  <MainLayout
    :sidebar-mini="sidebarIsMinimized"
    :hide-footer="!showFooter"
    :hideSidebar="!logged || !renderSidebar"
    :mobileMenuOpen="mobileMenuOpen"
    class="AppLayout"
  >
    <template #sidebar>
      <LayoutSidebar
        v-if="renderSidebar"
        logo_url="/agritask_insurance.svg"
        mini_logo_url="/agritask_logo.svg"
        :items="menuItems"
        :mini="sidebarIsMinimized"
        :logged="logged"
        :userName="userDetails.name"
        :userRole="userDetails.role"
        @toggleSidebar="sidebarMini = !sidebarMini"
        @logout="onLogout"
        @mobileMenuOpen="mobileMenuOpen = $event"
        @toggleUser="toggleSubPanel('user')"
        @closeSubpanelClick="toggleSubPanel()"
      >
        <template #postmenu>
          <NotificationIcon @click="toggleSubPanel('notifications')" :mini="sidebarIsMinimized" />
        </template>
        <template #subpanel v-if="isSubPanelDisplayed">
          <SubPanel
            v-if="isNotificationDisplayed"
            :title="$t('notifications.label')"
            @closeSubpanelClick="toggleSubPanel()"
          >
            <NotificationList @closeSubpanel="toggleSubPanel()" />
          </SubPanel>
          <SubPanel
            v-if="isUserActionsDisplayed"
            :title="$t('entities.users.single')"
            @closeSubpanelClick="toggleSubPanel()"
          >
            <UserActions
              @logout="onLogout"
              v-model:projectId="project_id"
              :projectOptions="projectOptions"
            />
          </SubPanel>
        </template>
      </LayoutSidebar>
    </template>
    <div class="p-relative">
      <router-view />
      <UploadEntitiesFile v-if="isShowFileUpload || isFileUploading" />
    </div>
    <template v-if="logged && isDesktop" #footer>
      <LayoutFooter>
        <template #start>
          <AppFooterDataBlocks />
        </template>
        <template #end>
          <button
            v-if="isInHomePage"
            role="button"
            class="footer-action"
            :class="{ active: hasPlotSelected }"
            :title="$t('map.actions.create_plot')"
            :disabled="hasPlotSelected"
            @click="gotoCreatePlot"
          >
            <VectorIcon size="20" />
          </button>
          <button
            v-if="isInHomePage"
            role="button"
            class="footer-action"
            :class="{ active: plotListOpen }"
            :title="$t('map.actions.show_filters')"
            @click="openPlotsList"
          >
            <ListIcon size="20" />
          </button>
          <div class="app-version">v.{{ version }}</div>
        </template>
      </LayoutFooter>
    </template>
  </MainLayout>
  <AtPopup
    v-if="atPopupProps.show"
    :show-confirmation-buttons="true"
    :warning-confirmation="true"
    v-bind="atPopupProps"
    v-on="atPopupListeners"
  >
  </AtPopup>
</template>

<script lang="ts">
  import {
    computed,
    defineAsyncComponent,
    defineComponent,
    provide,
    reactive,
    ref,
    watch,
  } from 'vue';
  import { ListIcon, VectorIcon } from 'vue-tabler-icons';

  import { AtButton, AtPopup, AtSelectTypes } from '@agritask/vue-components';
  import { getTranslators } from '@agritask/vue-components/utils';

  import useShowEntitiesUpload from '@/comps/popups/upload_file/hooks/useShowEntitiesUpload';

  import paths from '../router/paths';

  import AppFooterDataBlocks from './app_footer/AppFooterDataBlocks.vue';
  import './app_layout.scss';
  import useBreakPoint from './hooks/useBreakPoint';
  import { useRegisterConfirmPopup } from './hooks/useConfirmPopup';
  import useMainMenuItems from './hooks/useMainMenuItems';
  import usePages from './hooks/usePages';
  import useUser from './hooks/useUser';
  import MainLayout from './layout_app/MainLayout.vue';
  import LayoutFooter from './layout_app/footer/LayoutFooter.vue';
  import LayoutSidebar from './layout_app/sidebar/LayoutSidebar.vue';
  import LocaleSelector from './layout_app/sidebar/comps/LocaleSelector.vue';
  import NotificationIcon from './layout_app/sidebar/comps/NotificationIcon.vue';
  import NotificationList from './layout_app/sidebar/comps/NotificationList.vue';
  import SubPanel from './layout_app/sidebar/comps/SubPanel.vue';
  import UserActions from './layout_app/sidebar/comps/UserActions.vue';
  import SidebarBottom from './layout_app/sidebar_bottom/SidebarBottom.vue';
  import useMainDashboardState from './main_dashboard/hooks/useMainDashboardState';
  import { PlotSearch, PlotSearchInjectKey } from './main_dashboard/inject';

  const UploadEntitiesFile = defineAsyncComponent(
    /*webpackChunkName: "UploadEntitiesFile.vue"*/ () =>
      import('@/comps/popups/upload_file/UploadEntitiesFile.vue')
  );

  export default defineComponent({
    name: 'AppLayout',
    components: {
      LayoutSidebar,
      SidebarBottom,
      SubPanel,
      LocaleSelector,
      NotificationIcon,
      NotificationList,
      UserActions,
      LayoutFooter,
      MainLayout,
      AppFooterDataBlocks,
      ListIcon,
      VectorIcon,
      AtButton,
      AtPopup,
      UploadEntitiesFile,
    },
    setup() {
      const { t } = getTranslators();

      provide(PlotSearchInjectKey, ref<PlotSearch>({ search: {} }));

      const { isDesktop } = useBreakPoint();

      const { logged, permissions, user, swapProject, logout } = useUser();
      const { gotoMapPage, push, isInHomePage, route, isInPageWithoutSidebar } = usePages();

      const userDetails = reactive({ name: '', role: '' });

      const isLoggedUserCanCreatePolicy = computed(
        () => permissions.value?.operations.policies.create
      );

      const sidebarMini = ref(false);

      const renderSidebar = computed(() => !isInPageWithoutSidebar.value);

      const { hasPlotSelected, plotListOpen } = useMainDashboardState();
      const menuItems = useMainMenuItems();

      const { atPopupListeners, atPopupProps } = useRegisterConfirmPopup();

      const showFooter = computed(() => logged.value && route.name === paths.HOME);

      watch(
        () => user.value,
        (user) => {
          userDetails.name = user?.first_name || '';
          userDetails.role = user?.role ? t(`role.${user.role}`) : '';
        },
        { immediate: true }
      );

      // --- project ---
      const project_id = ref<number | undefined>(user.value?.project_id);

      const projectOptions = computed<AtSelectTypes.Option[]>(() => {
        return user.value!.projects.map(({ id, name }) => ({
          value: id,
          text: name,
        }));
      });

      watch(project_id, (pid) => {
        if (pid && pid !== user.value?.project_id) {
          swapProject(pid);
        }
      });

      // --- logout ---
      const onLogout = async () => {
        await logout();
        push({ name: paths.LOGIN });
      };

      const mobileMenuOpen = ref(false);

      const { isShowFileUpload, isFileUploading } = useShowEntitiesUpload();

      const isNotificationDisplayed = ref(false);
      const isUserActionsDisplayed = ref(false);

      const toggleSubPanel = (sidebar?: 'notifications' | 'user') => {
        switch (sidebar) {
          case 'notifications':
            isNotificationDisplayed.value = !isNotificationDisplayed.value;
            isUserActionsDisplayed.value = false;
            break;
          case 'user':
            isNotificationDisplayed.value = false;
            isUserActionsDisplayed.value = !isUserActionsDisplayed.value;
            break;
          default:
            isNotificationDisplayed.value = isUserActionsDisplayed.value = false;
        }
      };

      const isSubPanelDisplayed = computed(
        () => isNotificationDisplayed.value || isUserActionsDisplayed.value
      );

      const sidebarIsMinimized = computed(() => sidebarMini.value || isSubPanelDisplayed.value);

      return {
        sidebarMini,
        sidebarIsMinimized,
        renderSidebar,
        showFooter,
        gotoCreatePlot: () => gotoMapPage('create plot'),
        openPlotsList: () => gotoMapPage('toggle plot list'),
        userDetails,
        logged,
        onLogout,
        project_id,
        projectOptions,
        menuItems,
        version: window.web_api_version || '?',
        hasPlotSelected,
        plotListOpen,
        isInHomePage,
        atPopupListeners,
        atPopupProps,
        isLoggedUserCanCreatePolicy,
        isDesktop,
        mobileMenuOpen,
        isShowFileUpload,
        isFileUploading,
        toggleSubPanel,
        isSubPanelDisplayed,
        isNotificationDisplayed,
        isUserActionsDisplayed,
      };
    },
  });
</script>
