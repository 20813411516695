<template>
  <div class="RefreshPage page">
    <LoadingPlaceholder />
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted } from 'vue';
  import { useTimeout } from '@agritask/vue-components/hooks';

  import usePages from '../components/hooks/usePages';
  import LoadingPlaceholder from '../components/helpers/LoadingPlaceholder.vue';

  export default defineComponent({
    name: 'RefreshPage',
    components: { LoadingPlaceholder },
    setup() {
      const { timeout } = useTimeout();
      const { gotoMapPage } = usePages();

      const REFRESH_DELAY = 1000;

      onMounted(() => {
        timeout(gotoMapPage, REFRESH_DELAY);
      });

      return {};
    },
  });
</script>
