import { Ref, onBeforeUnmount, ref } from 'vue';

import { deleteEntity, getEntitiesAfter } from '@/src/api/entities';
import { fetchUnreadCount, sendMarkAsRead } from '@/src/api/notifications';
import EventEmitter from '@/src/utils/events/EventEmitter';

import useUser from '../useUser';

let unreadCount: Ref<number>;

const UNREAD_COUNT_POLL_TIME_MS = 1000 * 60; // once a minute
type MilliSeconds = number;
export const notificationEvents: EventEmitter<{ requestPoll: { delay: MilliSeconds } }> =
  new EventEmitter();

/**
 * Vue hook for managing notifications.
 * @returns {Object} An object containing notification-related state and functions.
 */
export default function useNotifications() {
  const { logged } = useUser();

  const requestUnreadCount = async () => {
    if (logged.value) {
      const response = await fetchUnreadCount();
      if (response !== false) {
        unreadCount.value = response;
      }
    }
  };

  if (!unreadCount) {
    unreadCount = ref(0);
    setInterval(() => requestUnreadCount(), UNREAD_COUNT_POLL_TIME_MS);
    requestUnreadCount();
  }

  const hasNotifications = ref(false);
  const notifications = ref<Notifications.Notification[]>([]);
  const isRefreshing = ref(false);

  const refreshNotification = async () => {
    isRefreshing.value = true;
    const result = await getEntitiesAfter('notifications', 'id', null, {
      columns: ['id', 'actions', 'body', 'pinned', 'read', 'severity', 'title', 'user_deletable'],
    });
    isRefreshing.value = false;
    if (result.ok && result.data) {
      notifications.value = result.data.entities as unknown as Notifications.Notification[];
      unreadCount.value = result.data.entities.filter((item) => !item.read).length;
    } else {
      unreadCount.value = 0;
    }
  };

  const setAsReadNotification = (id: number) => {
    sendMarkAsRead([id]);
    unreadCount.value = Math.max(0, unreadCount.value - 1);
  };

  const subtractAllUnreadsWithNoActionsFromUnreadCount = () => {
    const unreadWithNoActionsCount = notifications.value.reduce((acc, notification) => {
      if (!notification.read && !notification.actions?.length) {
        ++acc;
      }
      return acc;
    }, 0);

    if (unreadWithNoActionsCount) {
      unreadCount.value -= unreadWithNoActionsCount;
      if (unreadCount.value < 0) {
        unreadCount.value = 0;
      }
    }
  };

  const deleteNotification = async (notificationId: number): Promise<boolean> => {
    const index = notifications.value.findIndex(({ id }) => id === notificationId);
    let notification: Notifications.Notification;

    if (index === -1) {
      return false;
    }
    notification = notifications.value.splice(index, 1)[0];

    const updateUnreadCount = !notification.read && notification.actions;
    if (updateUnreadCount) {
      unreadCount.value = Math.max(0, unreadCount.value - 1);
    }

    const { ok = false } = await deleteEntity('notifications', notificationId);

    if (!ok) {
      // deletion failed - bring back the notification and the unread count if needed
      notifications.value.splice(index, 0, notification);
      if (updateUnreadCount) {
        unreadCount.value++;
      }
    }

    return ok;
  };

  const togglePin = async (notificationId: number): Promise<boolean> => {
    const notification = notifications.value.find(({ id }) => id === notificationId);
    if (!notification) {
      return false;
    }

    notification.pinned = !notification.pinned;
    alert('toggle pin notification missing API');

    return true;
  };

  // poll unread count on request
  const requestTimeoutIDs: Set<NodeJS.Timeout | number> = new Set();
  const onRequestUnreadTimeoutHandler = ({ delay }: { delay: number }) => {
    const timeoutID = setTimeout(() => {
      requestTimeoutIDs.delete(timeoutID);
      requestUnreadCount();
    }, delay);
    requestTimeoutIDs.add(timeoutID);
  };

  const clearAllUnreadRequests = () => {
    requestTimeoutIDs.forEach((timeoutId) => {
      clearTimeout(timeoutId);
    });
  };

  notificationEvents.addEventListener('requestPoll', onRequestUnreadTimeoutHandler);

  onBeforeUnmount(() => {
    clearAllUnreadRequests();
    notificationEvents.removeEventListener('requestPoll', onRequestUnreadTimeoutHandler);
  });

  return {
    unreadCount,
    hasNotifications,
    notifications,
    isRefreshing,
    refreshNotification,
    deleteNotification,
    setAsReadNotification,
    togglePin,
    subtractAllUnreadsWithNoActionsFromUnreadCount,
  };
}
