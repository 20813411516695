<template>
  <div class="LocaleSelector">
    <NavCollapsable :options="localeOptions" v-model="val">
      <template #icon>
        <LanguageIcon :size="20" />
      </template>
      {{ currentLanguage || $t('languages') }}
    </NavCollapsable>
  </div>
</template>

<script lang="ts">
  import { PropType, computed, defineComponent } from 'vue';
  import { LanguageIcon } from 'vue-tabler-icons';

  import { AtSelectTypes } from '@agritask/vue-components';

  import NavCollapsable from './NavCollapsable.vue';

  export default defineComponent({
    name: 'LocaleSelector',
    components: { NavCollapsable, LanguageIcon },
    props: {
      modelValue: { type: String, required: false },
      localeOptions: { type: Array as PropType<AtSelectTypes.Option[]>, default: () => [] },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
      const val = computed({
        get: () => props.modelValue || '',
        set: (value: string) => {
          emit('update:modelValue', value);
          emit('change', value);
        },
      });

      const currentLanguage = computed(() => {
        const selected = props.localeOptions.find(({ value }) => value === props.modelValue);
        return selected?.text || '';
      });

      return { val, currentLanguage };
    },
  });
</script>

<style lang="scss">
  .LocaleSelector {
    width: 100px;
    > .AtSelect {
      width: 100%;
      grid-gap: 0;
    }
  }
</style>
