<template>
  <button class="NavLinkLike" :class="{ ['dropdown-rotated']: dropdownArrowRotated }">
    <span class="p-relative">
      <slot name="icon" />
    </span>
    <span v-if="!mini" class="font-body-small capitalize p-relative flex-grow-1 d-flex ai-center">
      <slot />
      <span class="flex-grow-1"></span>
      <slot name="labelSuffix" />
      <ChevronRightIcon v-if="showRightArrow" :size="20" />
      <ChevronDownIcon class="down-arrow" v-else-if="showDropdownArrow" :size="20" />
    </span>
  </button>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { ChevronDownIcon, ChevronRightIcon } from 'vue-tabler-icons';

  export default defineComponent({
    name: 'NavLinkLike',
    components: { ChevronRightIcon, ChevronDownIcon },
    props: {
      showRightArrow: { type: Boolean, default: false },
      showDropdownArrow: { type: Boolean, default: false },
      dropdownArrowRotated: { type: Boolean, default: false },
      mini: { type: Boolean, default: false },
    },
    setup() {
      return {};
    },
  });
</script>

<style lang="scss">
  .NavLinkLike {
    display: flex;
    gap: var(--spacing-02);
    padding: var(--spacing-01);
    width: 100%;
    transition: background-color 150ms ease-out;
    color: var(--gray-900);

    &:hover {
      background-color: var(--pri-100);
    }

    .down-arrow {
      transition: transform 0.2s;
      inset-inline-start: unset !important;
      inset-inline-end: 0;
    }
    &.dropdown-rotated {
      .down-arrow {
        transform: rotate(180deg);
      }
    }
  }
</style>
