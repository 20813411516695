import { Policy } from '@/types/policy';

import { fetchGet } from '../utils';

interface FetchPolicyQuery {
  search?: Object;
}

export const fetchPoliciesWithPlots = (
  plots: (number | string)[],
  columns = ['id', 'policy_number', 'status']
) =>
  fetchGet<Policies.FetchPoliciesWithPlotsResponse<Partial<Policy>>>(`/api/policies/with_plots`, {
    plots,
    columns,
  });

export const fetchPolicyStatistics = (search?: Object) => {
  const queryParams: FetchPolicyQuery = {};

  if (search) {
    queryParams.search = JSON.stringify(search);
  }
  return fetchGet<Statistics.FetchPoliciesStatisticsResponse>(
    `/api/policies/statistics`,
    queryParams
  );
};

export const fetchPolicySupplemental = (search?: Object) => {
  const queryParams: FetchPolicyQuery = {};

  if (search) {
    queryParams.search = JSON.stringify(search);
  }
  return fetchGet<Statistics.FetchPoliciesSupplementalResponse>(
    `/api/policies/supplemental`,
    queryParams
  );
};
