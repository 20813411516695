import { VladSerial } from '@agritask/js-utils/cjs/input_validation/types';

import { covertUndefinedToNull } from '@/src/utils/object';
import { REVIEW_ENTITY_DETAILS } from '@/utils/entities/consts/review_entities';

import { fetchGet, fetchWithPayload } from '../utils';

export type Entity = Record<string, unknown> & { id?: number; deletedAt?: string };
export type CsvListType = string;
export type ColonListType = string; // example - '1:12'
export type ColonOrderListType = string; // example - 'id:DESC' | 'name:ASC'

export type Sorts = Record<string, 'asc' | 'desc'>;

export type SavedEntity = Entities.MinimalEntity;
export type GetEntityResult = {
  entity: SavedEntity;
  fields: EntityForm.Field[];
  validation: [VladSerial];
};
export type GetPermissionsResult = {
  fields: EntityForm.Field[];
  permissions: EntityPermissions.AccessLevelMapping;
  validation: [VladSerial];
};

interface CalculatePlotCoverageAreaQuery {
  plots: string;
  start_date: Date;
  end_date: Date;
  policyId?: number;
}

/**
 * Converts a JavaScript object containing sort key and directions into a string of colon-separated key and direction pairs.
 * @param {Object} sorts - The object containing sort directions.
 * @returns {string} The string representation of the sort directions in colon-separated key and direction pairs.
 */
export const convertSortsToColonOrderListType = (sorts: Sorts): ColonOrderListType => {
  return Object.entries(sorts)
    .map(([key, direction]) => {
      return `${key}:${direction}`;
    })
    .join(',');
};

/**
 * getEntities args
 */
interface GetEntitiesArgs {
  perPage: number;
  columns: string[];
  getFiltersSchema?: boolean;
  search?: Object;
  q_fields?: CsvListType;
  q?: string;
  orderBy?: ColonOrderListType;
  isReview?: boolean;
  jobId?: string | number | null;
}

/**
 * Sends a GET request to feetch a page of entities (list splitted to pages).
 * @param {string} type - The type of entity to retrieve.
 * @param {number} [page=1] - The page number to retrieve.
 * @param {GetEntitiesArgs} [args] - arguments for the API request
 * @return {Promise<Entities.GetAPIResponse>} - A promise that resolves to the response from the server.
 */
export const getEntities = (type: string, page = 1, args: GetEntitiesArgs) => {
  interface GetEntitiesQuery {
    page: number;
    perPage: number;
    columns: string[];
    filter_schema: 0 | 1;
    search?: Object;
    q_fields?: CsvListType;
    q?: string;
    orderBy?: ColonOrderListType;
  }

  const {
    perPage = 25,
    columns,
    getFiltersSchema = false,
    search,
    q_fields,
    q = '',
    orderBy,
    isReview = false,
    jobId,
  } = args;

  if (isReview) {
    columns.unshift(REVIEW_ENTITY_DETAILS);
  }

  const queryParams: GetEntitiesQuery = {
    page,
    perPage,
    columns,
    filter_schema: getFiltersSchema ? 1 : 0,
    q_fields: q_fields ? q_fields : '',
    q: q_fields ? q : '',
    orderBy: orderBy ? encodeURIComponent(orderBy) : '',
  };

  if (search) {
    queryParams.search = encodeURIComponent(JSON.stringify(search));
  }

  let url = `/api/entity/${type}`;
  if (isReview && jobId) {
    url += `/review/${jobId}`;
  }

  return fetchGet<Entities.GetAPIResponse>(url, queryParams);
};

/**
 * getEntitiesAfter args
 */
interface getEntitiesAfterArgs {
  perPage?: number;
  columns?: string[];
  getFiltersSchema?: boolean;
  search?: Object;
  q_fields?: CsvListType;
  q?: string;
}

/**
 * Sends a GET request to feetch a page of entities (list splitted to pages).
 * @param {string} type - The type of entity to retrieve.
 * @param {string} [orderBy] - the key to sort by
 * @param {GetEntitiesArgs} [args] - arguments for the API request
 * @return {Promise<Entities.GetAPIResponse>} - A promise that resolves to the response from the server.
 */
export const getEntitiesAfter = (
  type: string,
  orderBy: ColonOrderListType,
  latestOrderValue: string | number | null,
  args: getEntitiesAfterArgs
) => {
  interface GetEntitiesAfterQuery {
    orderBy: ColonOrderListType;
    latestOrderValue?: string | number;
    perPage: number;
    columns: string[];
    filter_schema: 0 | 1;
    search?: Object;
    q_fields?: CsvListType;
    q?: string;
  }

  const { perPage = 25, columns = [], getFiltersSchema = false, search, q_fields, q = '' } = args;

  const queryParams: GetEntitiesAfterQuery = {
    perPage,
    orderBy: encodeURIComponent(orderBy),
    columns,
    filter_schema: getFiltersSchema ? 1 : 0,
    q_fields: q_fields ? q_fields : '',
    q: q_fields ? q : '',
  };

  if (latestOrderValue) {
    queryParams.latestOrderValue = encodeURIComponent(latestOrderValue);
  }

  if (search) {
    queryParams.search = encodeURIComponent(JSON.stringify(search));
  }

  return fetchGet<Entities.GetAPIResponse>(`/api/entity/${type}/after`, queryParams);
};

/**
 * Retrieves the history for a given entity.
 * @param {string} type - The type of entity for which to retrieve the history.
 * @param {number} entityId - The ID of the entity for which to retrieve the history.
 * @returns {Promise<Entities.GetHistoryAPIResponse>} The response from the API, containing the history of the entity.
 */
export const fetchEntityHistory = (type: string, entityId: number) =>
  fetchGet<Entities.GetHistoryAPIResponse>(`/api/history/${type}/${entityId}`);

/**
 * Calculates the coverage area for a given set of plots, start date, end date, and policy ID (optional).
 * @param {number[]} plots - An array of plot IDs.
 * @param {string} start_date - The start date for the coverage calculation, in ISO-8601 format.
 * @param {string} end_date - The end date for the coverage calculation, in ISO-8601 format.
 * @param {number} [policyId] - The ID of the policy for which to calculate the coverage area. Optional.
 * @returns {Promise<Policies.GetCalculatePlotCoverageAPIResponse>} The response from the API, containing the coverage area calculation results.
 */
export const fetchCalculatePlotCoverageArea = (
  plots: number[],
  start_date: Date,
  end_date: Date,
  policyId = 0
) => {
  const queryParams: Partial<CalculatePlotCoverageAreaQuery> = {
    start_date,
    end_date,
    policyId,
  };

  if (plots) {
    queryParams.plots = plots.join(',');
  }

  return fetchGet<Policies.GetCalculatePlotCoverageAPIResponse>(
    `/api/policies/coverage_area/calculate`,
    queryParams
  );
};

/**
 * Sends a CRUD's Read GET request to the an entity.
 * @param {string} type - The type of entity to retrieve.
 * @param {string|number} id - The ID of the entity to retrieve.
 * @return {Promise<GetEntityResult>} - A promise that resolves to the response from the server.
 */
export const getEntity = async (type: string, id: string | number) =>
  fetchGet<GetEntityResult>(`/api/entity/${type}/${id}`);

/**
 * Sends a CRUD's Read GET request to the an entity.
 * @param {string} type - The type of entity to retrieve.
 * @param {string|number} id - The ID of the entity to retrieve.
 * @param {string|number} jobId - The ID of the job.
 * @return {Promise<GetEntityResult>} - A promise that resolves to the response from the server.
 */
export const getReviewEntity = async (type: string, id: string | number, jobId: string | number) =>
  fetchGet<GetEntityResult>(`/api/entity/${type}/review/${jobId}/${id}`);

/**
 * Sends a CRUD's Cread/Update POST/PUT request save an entity.
 * If the entity has an ID, a PUT (update) request is sent; otherwise, a POST (create) request is sent.
 * @param {string} type - The type of entity to send.
 * @param {Object} entity - The entity to send.
 * @return {Promise<{entity: T}>} - A promise that resolves to the response from the server.
 */
export const sendEntity = async <T>(type: string, entity: { id?: number | string }) => {
  const isUpdate = !!entity.id;
  let url = `/api/entity/${type}`;

  if (isUpdate) {
    url += `/${entity.id}`;
  }

  covertUndefinedToNull(entity);

  const response = await fetchWithPayload<{ entity: T }>(url, isUpdate ? 'PUT' : 'POST', entity);
  return response;
};

/**
 * Sends a CRUD's Delete DELETE request to delete an entity (internaly, it will be soft deleted).
 * @param {string} type - The type of entity to delete.
 * @param {number|string} id - The ID of the entity to delete.
 * @return {Promise<{id: number}>} - A promise that resolves to the response from the server.
 */
export const deleteEntity = async (type: string, id: number | string) =>
  fetchWithPayload<{ id: number }>(`/api/entity/${type}/${id}`, 'DELETE', {});

/**
 * Deletes entities of a specific type with the specified IDs.
 * @async
 * @function
 * @param {string} type - The type of entity to delete.
 * @param {CsvListType} ids - The IDs of the entities to delete, separated by commas.
 * @returns {Promise<{ ids: number[] }>} - A promise that resolves with an object containing the IDs of the deleted entities.
 */
export const deleteEntities = async (type: string, ids: number[]) =>
  fetchWithPayload<{ ids: number[] }>(`/api/entity/${type}`, 'DELETE', { ids });

/**
 * Sends a request to get access level, form fields and serialized Vlad validation rules for an entity - in the context of the logged user and a specific read/update/create action.
 * @param {string} type - The type of entity to retrieve permissions for.
 * @param {EntityPermissions.EntityAction} action - The action to retrieve permissions for.
 * @return {Promise<GetPermissionsResult|null>} - A promise that resolves to the response from the server, or null if the request was not successful.
 */
export const getPermissions = async (type: string, action: EntityPermissions.EntityAction) => {
  const result = await fetchGet<GetPermissionsResult>(`/api/entity/permissions/${type}`, {
    action,
  });
  return result.ok ? result.data : null;
};

/**
 * Returns true if the the ther is no Entity of a type with the a specific value of a specified field
 * @param type Entity type
 * @param field the field / key / column to search on
 * @param value the value of the field to check
 * @param excludedId exclude the Entity with matching id from the check
 * @returns
 */
export const checkIsUnique = async (
  type: Entities.EntityType,
  field: string,
  value: string,
  excludedId?: number
) => {
  const results = await getEntities(type, 1, {
    perPage: 2,
    columns: ['id', field],
    getFiltersSchema: false,
    search: { [field]: [value] },
  });

  if (!results.ok) {
    throw `failed to checkIsUnique for type "${type}" on field "${field}" with value "${value}"`;
  }
  const match = results.data!.entities.find(({ id }) => id !== excludedId);
  return !match;
};

export type CheckIsUnique = typeof checkIsUnique;
/**
 * Creates a checkIsUnique with results caching to prevent sending a request for checking value that was already checked
 * @param type Entity type
 * @param field the field / key / column to search on
 * @param value the value of the field to check
 * @param excludedId exclude the Entity with matching id from the check
 * @returns
 */
export const createCheckUnique = () => {
  const matchHistory = new Map<string, boolean>();

  const doCheck: CheckIsUnique = async (
    type: Entities.EntityType,
    field: string,
    value: string,
    excludedId?: number
  ) => {
    if (matchHistory.has(value)) {
      return matchHistory.get(value)!;
    }
    const matched = await checkIsUnique(type, field, value, excludedId);
    matchHistory.set(value, matched);
    return matched;
  };

  return doCheck;
};
