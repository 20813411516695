<template>
  <PageContent />
</template>

<script lang="ts">
  import { defineAsyncComponent, defineComponent } from 'vue';

  const PageContent = defineAsyncComponent({
    loader: () => import(/*webpackChunkName: "NotFound404.vue"*/ '../../pages/NotFound404.vue'),
  });

  export default defineComponent({
    components: { PageContent },
    setup() {},
  });
</script>
