import { nextTick } from 'vue';
import { I18n, createI18n } from 'vue-i18n';

import Injector from '@agritask/js-utils/libs/inject/Injector';
import { ServiceInjectionKey } from '@agritask/js-utils/libs/inject/Injector';

import EventEmitter from '../utils/events/EventEmitter';

type Key<T> = ServiceInjectionKey<T>;
const I18N: Key<I18n> = Symbol.for('il18n');
const LOCALE: Key<string> = Symbol.for('locale');

let i18nInstasnce: I18n;

export const locales = Object.freeze(window.locales ?? []);
const DEFAULT_LOCALE = locales[0];

export const i18nEventEmitter = new EventEmitter<{ loaded: string }>();

export const createI18nInstance = () => {
  const locale = getDefaultLocale() || DEFAULT_LOCALE;

  i18nInstasnce = createI18n({
    legacy: false,
    locale,
    fallbackLocale: DEFAULT_LOCALE,
  });

  Injector.bindToConstantValue(LOCALE, locale);
  Injector.bindToConstantValue(I18N, i18nInstasnce);

  return i18nInstasnce;
};

function getSavedLocale() {
  return null;
}

function getDefaultLocale() {
  return (
    getSavedLocale() ||
    document.querySelector('html')!.getAttribute('lang') ||
    navigator.language.split('-')[0] ||
    DEFAULT_LOCALE
  );
}

export const setI18nLanguage = async (locale?: string, i18n: I18n = i18nInstasnce) => {
  locale = locale || getDefaultLocale();

  await loadLocaleMessages(locale);

  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    // @ts-ignore-next-line
    i18n.global.locale.value = locale;
  }

  document.querySelector('html')!.setAttribute('lang', locale);
  document.querySelector('body')!.setAttribute('dir', locale === 'he' ? 'rtl' : 'ltr');
};

export async function loadLocaleMessages(locale: string, i18n: I18n = i18nInstasnce) {
  const vueComponentsMessagesImport = import(
    /* webpackChunkName: "locale-settings-[request]" */ `../../node_modules/@agritask/vue-components/src/js/vue/locales/${locale}.json`
  );

  const baseMessagesImport = import(
    /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
  );

  const timeMessagesImport = import(
    /* webpackChunkName: "locale-[request]" */ `@/locales/time/${locale}.json`
  );
  const entitiesMessagesImport = import(
    /* webpackChunkName: "locale-entities-[request]" */ `@/locales/entities/${locale}.json`
  );

  const mapMessagesImport = import(
    /* webpackChunkName: "locale-map-[request]" */ `@/locales/map/${locale}.json`
  );

  const plotMessagesImport = import(
    /* webpackChunkName: "locale-plot-[request]" */ `@/locales/plot/${locale}.json`
  );

  const policyMessagesImport = import(
    /* webpackChunkName: "locale-policy-[request]" */ `@/locales/policy/${locale}.json`
  );

  const settingsMessagesImport = import(
    /* webpackChunkName: "locale-settings-[request]" */ `@/locales/settings/${locale}.json`
  );

  const entityHistoryMessagesImport = import(
    /* webpackChunkName: "locale-history-[request]" */ `@/locales/entity_history/${locale}.json`
  );

  const entityOptionsMessagesImport = import(
    /* webpackChunkName: "locale-options-[request]" */ `@/locales/options/${locale}.json`
  );

  const uploadFileMessagesImport = import(
    /* webpackChunkName: "locale-upload-file-[request]" */ `@/locales/upload_file/${locale}.json`
  );

  const messagesImports = await Promise.all([
    baseMessagesImport,
    timeMessagesImport,
    entitiesMessagesImport,
    mapMessagesImport,
    plotMessagesImport,
    policyMessagesImport,
    settingsMessagesImport,
    vueComponentsMessagesImport,
    entityHistoryMessagesImport,
    entityOptionsMessagesImport,
    uploadFileMessagesImport,
  ]);
  const messages = messagesImports.reduce((acc, imp) => {
    return { ...acc, ...imp.default };
  }, {});
  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages);
  i18nEventEmitter.emit('loaded', locale);

  return nextTick();
}
