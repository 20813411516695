import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { createApp } from 'vue';

import Injector from '@agritask/js-utils/libs/inject/Injector';
import { AtToast } from '@agritask/vue-components';
import { AtToastPluginOptions } from '@agritask/vue-components/src/js/vue/at_toast/types';

import App from './App.vue';
import { getEnvironment } from './api/utils';
import { createI18nInstance } from './modules/i18n';
import tc from './modules/tc_plugin';
import { assignRouter } from './router';
import './style.scss';

const i18n = createI18nInstance();
Injector.bindToConstantValue(Symbol.for('il18n'), i18n);
const app = createApp(App);

app.use(i18n);
app.use(tc);

// toast
app.use(AtToast, {
  topInset: 'var(--spacing-04)',
  bottomInset: 'var(--spacing-04)',
  inlineInset: 'var(--spacing-04)',
} as AtToastPluginOptions);

const router = assignRouter(app);

const environment = getEnvironment();

if (environment !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://762c8b00a9594fd1adaaf02bb67c108b@o1295063.ingest.sentry.io/4503896003510272',
    environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'stg.agritask-rms.com', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

app.mount('#app');
