/**
 * Limits the number of decimal places in a given value.
 * @param {string | number} value - The value to limit the decimals for.
 * @param {number} fractionDigits - The number of decimal places to limit the value to.
 * @returns {number} - The limited value.
 */
export const limitDecimals = (value: string | number, fractionDigits: number) =>
  +(+value).toFixed(fractionDigits) || 0;

/**
 * Checks whether a given number is defined, not null, and not NaN.
 * @param {number | undefined | null} number - The number to check.
 * @returns {boolean} - Whether the number is defined, not null, and not NaN.
 */
export const isExistingNumber = (number: number | undefined | null) => {
  return typeof number === 'number' && !isNaN(number);
};

/**
 * An interface for defining the pretty prefix to be used with prettyNumber function.
 * @interface
 * @property {string} millions - The prefix to be used for millions.
 * @property {string} thousands - The prefix to be used for thousands.
 */
export interface PrettyPrefix {
  millions: string;
  thousands: string;
}

/**
 * Returns a formatted string representation of a number with a prefix.
 * @function prettyNumber
 * @param {number} number - The number to format.
 * @param {PrettyPrefix} prefix - The prefixs map to use.
 * @param {number} [fractionDigits=2] - The number of decimal places to include in the formatted string.
 * @returns {string} A formatted string representation of the input number with the specified prefix.
 */
export const prettyNumber = (
  number: number,
  prefix: PrettyPrefix,
  fractionDigits: number = 2
): string => {
  if (!number) {
    return '0';
  }
  if (number >= 1000000) {
    // millions
    return limitDecimals(number / 1000000, fractionDigits) + prefix.millions;
  } else if (number >= 1000) {
    return limitDecimals(number / 1000, fractionDigits) + prefix.thousands;
  }
  return limitDecimals(number, fractionDigits).toString();
};

/**
 * Returns a formatted string representation of a number with a prefix.
 * @function prettyUnknownNumber
 * @param {unknown} number - The number to format.
 * @param {PrettyPrefix} prefix - The prefixs map to use.
 * @param {number} [fractionDigits=2] - The number of decimal places to include in the formatted string.
 * @returns {string} A formatted string representation of the input number with the specified prefix.
 */
export const prettyUnknownNumber = (
  value: unknown,
  prefix: PrettyPrefix,
  fractionDigits: number = 2
) => {
  if (typeof value === 'number' || typeof value === 'string') {
    return prettyNumber(+value, prefix, fractionDigits);
  }
  return prettyNumber(0, prefix, fractionDigits);
};
