<template>
  <div class="AppFooterDataBlocks footer-data-block" v-for="data in dataBlocks" :key="data.label">
    <div class="footer-data-block-content">
      <span class="footer-data-block-label capitalize">{{ data.label }}</span>
      <br />
      <span class="footer-data-block-value">{{ data.value }}</span>
    </div>
  </div>
  <router-link
    class="PoliciesStatisticsLink AppFooterDataBlocks footer-data-block footer-data-block-content"
    :to="policyStatisticsRoute"
  >
    <ChartLineIcon />
    <span class="PoliciesStatisticsLink-text">
      {{ $t('map.statistics.label') }}
    </span>
  </router-link>
</template>

<script lang="ts">
  import { computed, defineComponent, inject } from 'vue';
  import { ChartLineIcon } from 'vue-tabler-icons';

  import paths from '@/src/router/paths';

  import useFetchPolicyStatistics from '../form/hooks/useFetchPolicyStatistics';
  import { PlotSearchInjectKey } from '../main_dashboard/inject';

  export default defineComponent({
    name: 'AppFooterDataBlocks',
    components: { ChartLineIcon },
    setup() {
      const plotsSearchInjection = inject(PlotSearchInjectKey);

      const { dataBlocks } = useFetchPolicyStatistics(plotsSearchInjection);

      const policyStatisticsRoute = computed(() => {
        let plotSearch = '';
        if (plotsSearchInjection?.value.search) {
          plotSearch = JSON.stringify(plotsSearchInjection?.value.search);
        }
        return {
          name: paths.POLICIES_STATISTICS,
          query: { plotSearch, policySearch: '' }, // TODO Send string-object with filters of policy
        };
      });

      return {
        dataBlocks,
        plotsSearchInjection,
        policyStatisticsRoute,
      };
    },
  });
</script>

<style lang="scss">
  @import '../../style/scss/layout';

  @include desktop {
    .footer-data-block {
      --data-block-spacing: 0.5em;

      color: var(--gray-300);
      min-width: calc(var(--footer-height) - var(--data-block-spacing));
      margin-inline-start: var(--data-block-spacing);
      line-height: 1em;

      &-content {
        padding: 0.2em 0.4em;
      }

      &-label {
        font-size: var(--font-size-x-small);
        margin-bottom: -1.2em;
        display: block;
      }
      &-value {
        color: var(--gray-100);
        font-size: 15px;
        font-weight: bold;
      }
    }

    .PoliciesStatisticsLink {
      cursor: pointer;
      padding: 0.5em 0.4em;
      &-text {
        position: relative;
        top: -0.4em;
        margin-inline-start: var(--spacing-02);
      }
    }
  }
</style>
