import { AtPopupTypes } from '@agritask/vue-components';
import { getTranslators } from '@agritask/vue-components/utils';

import { showConfirmPopup } from './useConfirmPopup';

const useConfirmStopEditingPopup = () => {
  return {
    showStopEditingEntityPopup,
    showStopGeneralEditingPopup,
  };
};

/**
 * Show abort editing confirmation popup
 * @param { string } contentText text for the popup's content
 * @param { Function } confirmedCallback callback when user confirms abort
 */
const showPopup = (contentText: string, confirmedCallback: Function) => {
  const { t } = getTranslators('abort_edit');
  showConfirmPopup(
    {
      show: true,
      appearance: AtPopupTypes.Appearance.WarningConfirmation,
      translationPath: '',
      contentText,
      titleText: t('title'),
      confirmText: t('confirm'),
      cancelText: t('cancel'),
    },
    confirmedCallback
  );
};

/**
 * Show abort editing confirmation popup for specific Entity type
 * @param { Entities.EntityType } entityType the Entity type that the editing of is aborted
 * @param { Function } confirmedCallback callback when user confirms abort
 */
export const showStopEditingEntityPopup = (
  entityType: Entities.EntityType,
  confirmedCallback: Function
) => {
  const { t } = getTranslators();
  showPopup(
    t('abort_edit.content', 0, { entity: t(`entities.${entityType}.single`) }),
    confirmedCallback
  );
};

/**
 * Show general abort editing confirmation popup
 * @param { Function } confirmedCallback callback when user confirms abort
 */
export const showStopGeneralEditingPopup = (confirmedCallback: Function) => {
  const { t } = getTranslators('abort_edit');
  showPopup(t('general_content'), confirmedCallback);
};

export default useConfirmStopEditingPopup;
