<template>
  <div class="NavCollapsable">
    <NavLinkLike
      :showDropdownArrow="true"
      :dropdownArrowRotated="isMenuOpen"
      @click="isMenuOpen = !isMenuOpen"
    >
      <template #icon>
        <slot name="icon" />
      </template>
      <slot />
    </NavLinkLike>
    <Transition name="collapse">
      <div class="collapsable-menu" v-if="isMenuOpen">
        <NavLinkLike
          v-for="option in options"
          :key="option.value"
          @click="$emit('update:modelValue', option.value)"
          :class="{ selected: option.value === modelValue }"
        >
          {{ option.text }}
        </NavLinkLike>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent, ref } from 'vue';

  import { AtSelectTypes } from '@agritask/vue-components';

  import NavLinkLike from './NavLinkLike.vue';

  export default defineComponent({
    name: 'NavCollapsable',
    components: { NavLinkLike },
    props: {
      options: { type: Array as PropType<AtSelectTypes.BaseOption[]>, default: () => [] },
      modelValue: { type: [String, Number], required: false },
    },
    emits: ['update:modelValue'],
    setup() {
      const isMenuOpen = ref(false);

      return {
        isMenuOpen,
      };
    },
  });
</script>

<style lang="scss">
  .NavCollapsable {
    .collapsable-menu {
      padding-inline-start: var(--spacing-05);
    }
    .selected {
      .font-body-small {
        font-weight: bold;
      }
    }
  }
</style>
