<template>
  <NavLinkLike
    :showRightArrow="true"
    class="NotificationIcon NavLinkLike"
    :class="{ unread: unreadCount > 0 }"
    :mini="mini"
  >
    <template #icon>
      <BellIcon :size="20" />
      <span class="p-relative">
        <b v-if="unreadCount && mini" class="count no-events p-absolute d-block">{{
          unreadCount
        }}</b>
      </span>
    </template>
    {{ $t('notifications.label') }}
    <template #labelSuffix>
      <b v-if="unreadCount" class="count no-events d-inline-block">{{ unreadCount }}</b>
    </template>
  </NavLinkLike>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { BellIcon } from 'vue-tabler-icons';

  import useNotifications from '@/hooks/notifications/useNotifications';

  import NavLinkLike from './NavLinkLike.vue';

  export default defineComponent({
    name: 'notificationIcon',
    components: { NavLinkLike, BellIcon },
    props: {
      mini: { type: Boolean, default: false },
    },
    setup() {
      const { unreadCount } = useNotifications();

      return {
        unreadCount,
      };
    },
  });
</script>

<style lang="scss">
  .NotificationIcon {
    color: inherit;

    .count {
      color: var(--white);
      top: 0;
      inset-inline-start: 100%;
      transform: translate(-35%, -85%);
      width: 1.4em;
      height: 1.4em;
      border-radius: 3px;
      background: var(--red-500);
      font-size: 0.9em;
      line-height: 1.45em;
    }

    .font-body-small {
      text-align: start;
      justify-content: space-between;

      .count {
        text-align: center;
        transform: translate(0);
      }
    }
  }
</style>
