import { onBeforeUnmount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { setWindowTabName } from '@/src/utils/nav/navigation';

import { i18nEventEmitter, locales } from '../../modules/i18n';

import usePages from './usePages';

const useLocale = () => {
  const { locale } = useI18n();
  const { route } = usePages();
  const loadedLocale = ref<string>();
  const localeFromFooter = ref('');

  watch(locale, (value) => {
    localeFromFooter.value = value;
  });

  const handler = (locale: string) => {
    loadedLocale.value = locale;
    setWindowTabName(route);
  };

  i18nEventEmitter.addEventListener('loaded', handler);

  onBeforeUnmount(() => i18nEventEmitter.removeEventListener('loaded', handler));

  return {
    locale,
    loadedLocale,
    locales,
    localeFromFooter,
  };
};

export default useLocale;
