/**
 * Increments the counter associated with a specific key in a Map.
 * @template T - The type of the key.
 * @param {T} key - The key whose counter to increment.
 * @param {Map<T, number | undefined>} vault - The Map where the counters are stored.
 * @param {number} offset - The value by which to increment the counter.
 * @returns {number} - The updated counter value after the increment operation.
 */
export function incrementKeysCounter<T>(
  key: T,
  vault: Map<T, number | undefined>,
  offset: number
): number {
  if (!vault.has(key) && offset <= 0) {
    return 0;
  }
  const newValue = (vault.get(key) || 0) + offset;
  if (newValue > 0) {
    vault.set(key, newValue);
    return newValue;
  }
  vault.delete(key);
  return 0;
}

/**
 * get value of a specific key.
 * @template T - The type of the key.
 * @param {T} key - The key to get it's value.
 * @param {Map<T, number | undefined>} vault - The Map where the counters are stored.
 * @param {[number=0]} defaultValue - Default value if there is no value of provided key or it's 0 or less
 * @returns
 */
export const getValueOfKey = <T>(
  key: T,
  vault: Map<T, number | undefined>,
  defaultValue: number = 0
) => {
  const value = vault.get(key) || 0;
  return value > 0 ? vault.get(key) : defaultValue;
};
