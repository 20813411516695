import { Ref, onBeforeUnmount, onMounted, ref } from 'vue';

import useBreakPoint from '@/hooks/useBreakPoint';

/**
 * Vue hook to flag if to hide mobile header on scroll.
 * @param {number} [minimumScroll=100] - The minimum scroll value at which the header should be hidden.
 * @returns {object} - An object containing the `hideHeader` reactive property.
 */
export default function useHideMobileHeaders(mobileMenuOpen: Ref<boolean>, minimumScroll = 100) {
  const { isMobile } = useBreakPoint();

  const hideHeader = ref(false);
  let previousScroll = 0;

  const onScroll = () => {
    const { scrollY } = window;
    hideHeader.value =
      !mobileMenuOpen.value &&
      isMobile.value &&
      previousScroll < scrollY &&
      scrollY > minimumScroll;

    previousScroll = scrollY;
  };

  onMounted(() => {
    previousScroll = window.scrollY;
    window.addEventListener('scroll', onScroll);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', onScroll);
  });

  return {
    hideHeader,
  };
}
