<template>
  <div class="SubPanel">
    <header class="d-flex block-end-04">
      <h3 class="flex-grow-1 capitalize">{{ title }}</h3>
      <button class="subpanel-close" @click="$emit('closeSubpanelClick')">
        <XIcon />
      </button>
    </header>
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { XIcon } from 'vue-tabler-icons';

  export default defineComponent({
    name: 'SubPanel',
    props: { title: { type: String, default: '' } },
    components: { XIcon },
    setup() {
      return {};
    },
  });
</script>

<style lang="scss">
  .SubPanel {
    width: 228px;

    .subpanel-close {
      color: inherit;
    }
  }
</style>
