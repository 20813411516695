/**
 * Sends a GET request to the specified URL with the specified url query.
 * @param {string} url - The URL to send the request to.
 * @param {Object} [urlQuery] - The parameters to include in the request.
 * @return {Promise<IAPIResponse<T>>} - A promise that resolves to the response from the server.
 */
export const fetchGet = <T>(url: string, urlQuery?: any) => {
  const _csrf = getCSRFToken();
  urlQuery = { ...urlQuery, _csrf: _csrf };
  if (urlQuery) {
    url +=
      '?' +
      Object.entries(urlQuery)
        .map(([key, value]) => `${key}=${encodeValueIfNonUTC8(value)}`)
        .join('&');
  }

  const headers = getHeaders();

  return fetch(url, { credentials: 'include', headers }).then((res) => res.json()) as Promise<
    IAPIResponse<T>
  >;
};

/**
 * Encodes a value if necessary, with consideration for dates and non-UTC-8 characters.
 * @param {unknown} value - The value to be encoded.
 * @returns {string | unknown} - The encoded value if it was a string with non-UTC-8 characters, or the original value otherwise.
 */
export const encodeValueIfNonUTC8 = (value: unknown): string | unknown => {
  if (value instanceof Date) {
    value = value.toString();
  }
  if (typeof value === 'string') {
    const isFromUTC8 = /^[\u0000-\u007F]*$/.test(value);
    if (!isFromUTC8) {
      return encodeURIComponent(value);
    }
  }
  return value;
};

/**
 * Checks if a string contains only characters from the UTC-8 range.
 * @param {string} input - The input string to be checked.
 * @returns {boolean} - True if the string contains only UTC-8 characters, false otherwise.
 */
export const isStringFromUTC8 = (input: string): boolean => {
  const utc8Regex = /^[\u0000-\u007F]*$/;
  return utc8Regex.test(input);
};

/**
 * Sends a POST, PUT, or DELETE request to the specified URL
 * @param {string} url - The URL to send the request to.
 * @param {'POST'|'PUT'|'DELETE'} method - The HTTP method to use for the request.
 * @param {Object} [body=null] - The body to include in the request.
 * @param {string} [contentType='application/json'] - The content type of the request body.
 * @return {Promise<IAPIResponse<T>>} - A promise that resolves to the response from the server.
 */
export const fetchWithPayload = <T>(
  url: string,
  method: 'POST' | 'PUT' | 'DELETE',
  body: any = null,
  contentType: string = 'application/json'
) => {
  const headers = getHeaders();

  const _csrf = getCSRFToken();

  body = body || {};

  return fetch(url, {
    method,
    body: JSON.stringify({ _csrf, ...body }),
    headers: { 'Content-type': contentType, ...headers },
    credentials: 'include',
  }).then((res) => res.json()) as Promise<IAPIResponse<T>>;
};

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    csrf_token?: string;
    env?: string;
    was_logged?: boolean;
    web_api_version?: number;
    locales?: string[];
    google_client?: string;
    onGoogleMapsReady?: Function;
  }
}

const getCSRFToken = () => window.csrf_token;

/**
 * Returns the current environment, either 'development' or 'production'.
 * @return {string} - The current environment.
 */
export const getEnvironment = () => window.env || 'development';

/**
 * Returns an object containing the CSRF token as the value for various header keys.
 * @return {Object} - An object containing the CSRF token as the value for various header keys.
 */
const getHeaders = () => {
  const token = getCSRFToken();
  if (token) {
    return {
      'csrf-token': token,
      'xsrf-token': token,
      'x-csrf-token': token,
      'x-xsrf-token': token,
    };
  }
};
